import { ILogMessage, Patch } from '../common/api';
import { AscDesc } from '../search';
import { IFileAppearanceCommonStatuses, IFileCommon } from './file';

export enum IOwnFileTypes {
  Supplementary = 1,
  Verification,
}

export enum IOwnFileVerificationStatuses {
  NotVerified = 1,
  Pending,
  Verified,
  Dismissed,
}

export type IOwnFile = IFileCommon & {
  idAppearanceStatus: IFileAppearanceCommonStatuses;
  idVerificationStatus: IOwnFileVerificationStatuses;
};

export type IOwnFileSearchItem = IOwnFile;

export type IOwnFileUploadPayload = FormData;

export type IOwnFileUploadRequest = IOwnFileUploadPayload;

export type IOwnFileUpdatePayload = ILogMessage & {
  customerName?: string;
  customerDescription?: string;
};

export type IOwnFileUpdateRequest = Patch<IOwnFileUpdatePayload>;

export type IOwnFileShowPayload = ILogMessage;

export type IOwnFileShowRequest = Patch<IOwnFileShowPayload>;

export type IOwnFileHidePayload = ILogMessage;

export type IOwnFileHideRequest = Patch<IOwnFileHidePayload>;

export type IOwnFileRemovePayload = ILogMessage;

export type IOwnFileRemoveRequest = Patch<IOwnFileRemovePayload>;

export type IOwnFileSearchParams = {
  idFrom?: number;
  idTo?: number;
  dateAddedFrom?: string;
  dateAddedTo?: string;
  name?: string;
  extension?: string;
  dynamicS3Link?: string;
  idType?: IOwnFileTypes[];
  idAppearanceStatus?: IFileAppearanceCommonStatuses[];
  idVerificationStatus?: IOwnFileVerificationStatuses[];
  sortType?: AscDesc;
  sortBy?: OwnFileSortField;
  page?: number;
  size?: number;
};

export type OwnFileSortField = 'id' | 'name' | 'dateAdded' | 'extension' | 'size' | 'idAppearanceStatus' | 'idVerificationStatus';
