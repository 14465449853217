import { Button } from '@/components/common/button/Button';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { useProfile } from '@/shared/hooks/useProfile';
import { setShowSetPasswordNotification, showSetPasswordNotificationSelector } from '@/store/localPreferences/localPreferenceSlice';
import { IProfileInfo } from '@/types/profile';
import { ProfileAvatar } from '@feature/profile/ProfileAvatar';
import { ProfileDetails } from '@feature/profile/ProfileDetails';
import { ProfilePasswordNotificationPopoverContent } from '@feature/profile/ProfilePasswordNotificationPopoverContent';
import { Popover } from 'antd';
import cn from 'classnames';
import { useEffect, useState } from 'react';

import s from './ProfilePopover.module.less';

type Props = {
  className?: string;
};

export const ProfilePopover = ({ className }: Props) => {
  const { isLoadingProfile, ...profile } = useProfile();
  const [visible, setVisible] = useState(false);

  const showSetPasswordNotification = useAppSelector(showSetPasswordNotificationSelector);

  const dispatch = useAppDispatch();

  const handleVisibleChange = (visible: boolean) => {
    if (visible) {
      dispatch(setShowSetPasswordNotification(false));
    }
    setVisible(visible);
  };

  const passwordSet = profile.passwordSet;

  const displayPasswordNotification = showSetPasswordNotification && 'passwordSet' in profile && !passwordSet;

  useEffect(() => {
    if (displayPasswordNotification) {
      setVisible(true);
    }
  }, [displayPasswordNotification]);

  return (
    <div className={cn(s.wrapper, className)}>
      <Popover
        trigger={'click'}
        open={visible}
        onOpenChange={handleVisibleChange}
        placement={'bottomRight'}
        content={
          profile &&
          (displayPasswordNotification ? (
            <ProfilePasswordNotificationPopoverContent onClick={() => setVisible(false)} />
          ) : (
            <div className={s.content}>
              <ProfileDetails profile={profile as IProfileInfo} setVisible={setVisible} />
            </div>
          ))
        }
      >
        <span>
          <ProfileAvatar fallback={<Button icon={'IcoUserOutlined'} modification={'gradient'} circle loading={isLoadingProfile} />} />
        </span>
      </Popover>
    </div>
  );
};
