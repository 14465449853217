import { AppContext } from '@/app/contexts/AppContext';
import { ErrorPage } from '@/components/common/error/ErrorPage';
import { SpinFullscreen } from '@/components/common/spin/SpinFullscreen';
import { INITIAL_PREFERENCES } from '@/config/constants';
import { useTrackBreakpoint } from '@/hooks/breakpoints/useTrackBreakpoint';
import { useCssVariables } from '@/hooks/cssVariables';
import { useAppDispatch } from '@/hooks/redux';
import { useViewPortHeight } from '@/hooks/viewportHeight';
import { useAutoSignUp, useMagicLogin, useQueryLogout, useTrackUserId } from '@/store/auth/authHooks';
import { useGetPreferencesQuery } from '@/store/emptyApi';
import { setMaintenance } from '@/store/global/globalSlice';
import { merge } from 'lodash';
import { useEffect } from 'react';
import { getErrorCode } from '@/helpers/utils';

// TODO: @max, check comments! request sync for explanation
export const AppProvider = ({ children }) => {
  const { data: preferences, isLoading, isError, error } = useGetPreferencesQuery();
  const breakpoint = useTrackBreakpoint();
  useViewPortHeight(); // @max -> why this code placed in this module?
  const dispatch = useAppDispatch();

  useCssVariables(preferences?.theme);

  useEffect(() => {
    if (preferences?.assets?.favicon) {
      const oldFavicon = document.getElementById('favicon');
      const newFavicon = document.createElement('link');

      newFavicon.id = 'favicon';
      newFavicon.rel = 'shortcut icon';
      newFavicon.href = preferences.assets.favicon;
      newFavicon.type = 'image/x-icon';

      if (oldFavicon) {
        document.head.removeChild(oldFavicon);
      }
      document.head.prepend(newFavicon);
    }
  }, [preferences?.assets?.favicon]);

  // @max -> why this code placed in this module?
  useEffect(() => {
    dispatch(setMaintenance(false)); // ? false is a default value
  }, [dispatch]);

  useQueryLogout(); // @max -> why this code placed in this module?
  useMagicLogin(); // @max -> why this code placed in this module?
  useTrackUserId(); // @max -> why this code placed in this module?
  useAutoSignUp(); // @max -> why this code placed in this module?

  const isTouchDevice = !window.matchMedia('(hover: hover) and (pointer: fine)').matches;

  if (isLoading) return <SpinFullscreen />;
  if (isError || !preferences) return <ErrorPage type={getErrorCode(error)} />;

  return (
    <AppContext.Provider
      value={{
        breakpoint,
        isTouchDevice,
        config: merge(INITIAL_PREFERENCES, preferences),
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
