import { API_BASE_URL, X_HOST } from '@/config/constants';
import { FEATURE, FEATURE_LIST, FeatureType } from '@/config/features';
import { notification } from '@/helpers/notification';
import { useAppDispatch } from '@/hooks/redux';
import { authLogout } from '@/store/auth/authActions';
import { refreshAccessToken } from '@/store/auth/authSlice';
import { setMaintenance } from '@/store/global/globalSlice';
import { RootState } from '@/store/store';
import { IAccessToken } from '@/types/auth';
import { ApiEndpointExtraOptions, ErrorResponse } from '@/types/common/api';
import { ISitePreferences, ISitePreferencesResponse } from '@/types/common/preferences';
import { IOrderForm } from '@/types/orderForm';
import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import qs from 'query-string';

let refreshTokenPromise: Promise<boolean> | null = null;

const baseQuery = fetchBaseQuery({
  baseUrl: API_BASE_URL,
  paramsSerializer: (params) => qs.stringify(params, { skipEmptyString: true, skipNull: true }),
  prepareHeaders: (headers, { getState }) => {
    const { accessToken } = (getState() as RootState).auth.tokens;
    accessToken && headers.set('Authorization', `Bearer ${accessToken}`);
    headers.set('x-host', X_HOST);
    return headers;
  },
});

let _api;
const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions: ApiEndpointExtraOptions,
) => {
  extraOptions?.log && console.log('baseQueryWithReauth > log', { args, api, extraOptions });

  const initialAccessToken = (api.getState() as RootState).auth.tokens.accessToken;

  if (refreshTokenPromise) await refreshTokenPromise;

  // Original request
  const result = await baseQuery(args, api, extraOptions);

  // if request is successful do nothing
  if (!result.error) return result;

  const { status } = result.error;

  // Set Maintenance State
  if (status === 503) {
    api.dispatch(setMaintenance(true));
    return result;
  }

  const currentAccessToken = (api.getState() as RootState).auth.tokens.accessToken;

  // Access token expired
  if (status === 401) {
    const { refreshToken } = (api.getState() as RootState).auth.tokens;

    // if no refresh token logout and return error
    if (!refreshToken) {
      api.dispatch(authLogout(_api));
      return result;
    }

    // Swap tokens before refresh
    const isRefreshRequestRequired =
      !refreshTokenPromise && (currentAccessToken === initialAccessToken || currentAccessToken !== refreshToken);

    if (isRefreshRequestRequired) {
      api.dispatch(refreshAccessToken({ accessToken: refreshToken }));

      refreshTokenPromise = (async () => {
        try {
          // Swap tokens before refresh
          const result = await baseQuery({ url: '/auth/refresh', method: 'POST' }, api, extraOptions);

          const { accessToken } = (await result.data) as IAccessToken;

          api.dispatch(refreshAccessToken({ accessToken }));
          refreshTokenPromise = null;
          return true;
        } catch (err) {
          api.dispatch(authLogout(_api)); // refresh access token failed!
          return false;
        }
      })();
    }

    const refreshResult = refreshTokenPromise ? await refreshTokenPromise : false;

    if (refreshResult) {
      return await baseQuery(args, api, extraOptions);
    }
  }

  // Forbidden or tokens revoked
  if (status === 403) {
    api.dispatch(authLogout(_api));
    // msg.error('Action not allowed');
    return result;
  }

  // Ignore 400/404 error
  if (status === 400 || status === 404) return result;

  /** Global error handler
   * Use extraOptions -> customErrorHandler for disable this handler
   *     endpointName: build.mutation<R,A>({
   *        ...
   *       extraOptions: { customErrorHandler: true }, // <- skip global error handler
   *     }),
   */
  if (!extraOptions?.customErrorHandler) {
    const message: string = (result.error as ErrorResponse)?.data?.error?.replace(/`/g, '').substring(0, 200) || 'Unknown server error';
    notification.error({ message }); // notification instead of msg -> coz: large text
  }
  console.error('❗️ API', result); // always show console.error

  return result;
};

const tagTypes = [...FEATURE_LIST, 'TicketMessenger', 'Preferences'];

export const emptyApi = (_api = createApi({
  reducerPath: '_customer-api',
  tagTypes,
  baseQuery: baseQueryWithReauth,
  refetchOnReconnect: true,
  refetchOnFocus: true,
  endpoints: (build) => ({
    getPreferences: build.query<ISitePreferences, void>({
      query: () => ({ url: '/preferences' }),
      transformResponse: (response: ISitePreferencesResponse) => {
        const { preferences, ...rest } = response;
        return { ...preferences, ...rest }; // todo: refactor -> container flags from API!
      },
      providesTags: ['Preferences'],
      extraOptions: { customErrorHandler: true },
    }),
    // For prefetch after resetApiState()
    getOrderForm: build.query<IOrderForm, void>({
      query: () => ({
        url: FEATURE.OrderForm.path,
      }),
    }),
  }),
}));

export const { useGetPreferencesQuery, useGetOrderFormQuery } = emptyApi;

export const getPath = (feature: FeatureType): string => FEATURE[feature].path;

// Same tags for all entities!
// Works great!
export const getTags = (type: FeatureType, dependentTypes?: FeatureType[]) => ({
  // Mutations
  invalidatesTags: (result, error, args) => {
    let tags: { type: FeatureType; id?: string }[] = [
      { type }, // main
      { type, id: `associations` },
      { type, id: `search` },
    ];

    // Single view
    const id = typeof args === 'number' ? args : args?.id;
    if (id && Number(id)) tags.push({ type, id: `${id}` }); // add single

    dependentTypes?.forEach((tag) => {
      tags.push({ type: tag }); // TODO: Brainstorm for optimize? ; upd: @max, check api/order -> CustomerFiles section
    });

    return tags;
  },
  // Queries
  providesTags: (result, error, id) => [{ type, id }], // for single view only
  // Search view ->  providesTags: () => [{ type, id: 'search' }],
  // Other entity associations view ->  providesTags: () => [{ type, id: 'associations' }],
});

/**
 * Manual invalidatesTags (cache control & refetch subscribers)
 * Fo
 * @param type as FEATURES keys
 * @param id target
 * @param prefix lazy container
 */
export const useInvalidatesTags = (type: FeatureType, id: string | number = 0, prefix = '') => {
  const dispatch = useAppDispatch();
  let tag: { type: FeatureType; id?: number | string } | FeatureType = type; // Reset all cache by type (default)
  if (id) {
    // A) Lazy containers
    //    Refetch only lazy container (according to build.query -> provideTags)
    //    example:
    //      providesTags: (result, error, { id, context }) =>
    //        [{ type: context, id: YOUR_CUSTOM_PREFIX + id }],
    // B) Single view
    //    refetch single view too
    //    TODO: Move all relations to "lazy containers" and remove this tag
    tag = prefix ? { type, id: `${prefix}${id}` } : { type, id };
  }
  return () => dispatch(emptyApi.util.invalidateTags([tag]));
};
