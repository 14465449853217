import { IIconSVG } from '@/components/common/icons/IconSvg';

type FeatureItem<T> = {
  type: T;
  title: string;
  path: `/${string}`;
  apiPath?: `/${string}`;
  menu?: {
    heading: string;
    icon: IIconSVG;
  };
};

type Features = Record<string, FeatureItem<string>>;

type Validate<T extends Features> = {
  [FeatureType in keyof T]: FeatureType extends T[FeatureType]['type'] ? T[FeatureType] : T[FeatureType] & { type: never };
};

const featureTypeValidator = <
  FeatureType extends string,
  Feature extends FeatureItem<FeatureType>,
  Data extends Record<FeatureType, Feature>,
>(
  data: Validate<Data>,
) => data;

export const FEATURE = featureTypeValidator({
  Dicts: {
    title: 'Dicts',
    path: '/dicts',
    type: 'Dicts',
  },
  SiteSettings: {
    title: 'SiteSettings',
    path: '/site-settings',
    type: 'SiteSettings',
  },
  Auth: {
    title: 'Auth',
    path: '/auth',
    type: 'Auth',
  },
  OrderForm: {
    title: 'Order Form',
    path: '/orderform',
    type: 'OrderForm',
  },
  PriceQuote: {
    title: 'Price Quote',
    path: '/price-quote',
    type: 'PriceQuote',
  },
  Order: {
    title: 'Order',
    path: '/orders',
    type: 'Order',
    menu: {
      heading: 'My Orders',
      icon: 'IcoRFiles',
    },
  },
  PreferredWriter: {
    title: 'Preferred Writer',
    path: '/preferred-writers',
    type: 'PreferredWriter',
    menu: {
      heading: 'Preferred writers',
      icon: 'IcoRFiles',
    },
  },
  Profile: {
    title: 'Profile',
    path: '/profile',
    type: 'Profile',
  },
  OwnFile: {
    title: 'OwnFile',
    path: '/own-files',
    type: 'OwnFile',
  },
  Revision: {
    title: 'Revision',
    path: '/revisions',
    type: 'Revision',
    menu: {
      heading: 'Revisions',
      icon: 'IcoRArrowsRepeat',
    },
  },
  Ticket: {
    title: 'Ticket',
    path: '/tickets',
    type: 'Ticket',
    menu: {
      heading: 'Tickets',
      icon: 'IcoRTicket',
    },
  },
  CustomerExternalCredential: {
    title: 'Customer External Credential',
    path: '/login-details',
    apiPath: '/customer-external-credentials',
    type: 'CustomerExternalCredential',
    menu: {
      heading: 'Login details',
      icon: 'IcoRClipboardList',
    },
  },
  IncomingTransactions: {
    title: 'Incoming Transactions',
    path: '/transactions',
    apiPath: '/incoming-transactions',
    type: 'IncomingTransactions',
    menu: {
      heading: 'Transactions',
      icon: 'IcoRMoneyBills',
    },
  },
  Feedbacks: {
    title: 'Feedbacks',
    path: '/feedbacks',
    type: 'Feedbacks',
  },
});

export const FEATURE_LIST = Object.keys(FEATURE);
export type FeatureType = typeof FEATURE_LIST[number];

export const getURL = (feature: FeatureType, suffix?: string | number): string => {
  const _feature = FEATURE[feature];
  if (!_feature) return 'n\\a';
  const { path } = _feature;
  return `${path}${suffix ? `/${suffix}` : ''}`;
};

type TSiteMenu = {
  name: string;
  list: FeatureItem<any>[];
};

export const SITE_MENUS: TSiteMenu[] = [
  {
    name: 'Order',
    list: [
      FEATURE.Order,
      // FEATURE.PreferredWriter,
      FEATURE.Revision,
      FEATURE.Ticket,
    ],
  },
  {
    name: 'Other',
    list: [FEATURE.CustomerExternalCredential, FEATURE.IncomingTransactions],
  },
];
