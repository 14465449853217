import { ITokenPair } from '@/shared/types/auth';
import { TicketOrigins, TicketStatuses } from '@/types/ticket';

export enum WSReadyState {
  Connecting,
  Open,
  Closing,
  Closed,
}

// Websocket Gateway Microservice
export enum WSEventTypes {
  Ping = 1,
  Pong,

  Issue,
  IssueMessage,
  IssueTag,

  Ticket,
  TicketMessage,
}

export enum WSEventTypeIds {
  TicketMessageCreated = 1,
  TicketCreated,
  OrderPaid,
  RevisionCreated,
  TicketMessageCreatedByUser = 100,
  TicketMessageCreatedByWriter,
  TicketMessageCreatedByCustomer,
  TicketMessageUpdatedByUser,
  TicketMessageUpdatedByWriter,
  TicketMessageUpdatedByCustomer,
  TicketMessageRetractedByUser,
  TicketMessageRetractedByWriter,
  TicketMessageRetractedByCustomer,
  TicketMessageRemovedByUser,
  TicketMessageConfirmedByUser,
  TicketMessageDeclinedByUser,
  TicketMessageVisibilityCustomer,
  TicketMessageVisibilityWriter,
  TicketMessageVisibilityPrivate,
  TicketMessageVisibilityPublic,

  TicketCreatedByUser = 200,
  TicketCreatedByWriter,
  TicketCreatedByCustomer,
  TicketUpdatedByUser,
  TicketUpdatedByWriter,
  TicketUpdatedByCustomer,
  TicketStatusChangedByUser,
  TicketStatusChangedByWriter,
  TicketStatusChangedByCustomer,
  TicketVisibilityChangedByUser,
  TicketVisibilityChangedByWriter,
  TicketVisibilityChangedByCustomer,
  TicketFileChangedByUser,
  TicketFileChangedByWriter,
  TicketFileChangedByCustomer,
  RevisionCreatedByUser = 300,
  RevisionCreatedByCustomer,
  RevisionUpdatedByUser,
  RevisionUpdatedByCustomer,
  RevisionStatusChangedByUser,
  RevisionStatusChangedByWriter,
  RevisionStatusChangedByCustomer,

  RevisionFileChangedByUser,
  RevisionFileChangedByWriter,
  RevisionFileChangedByCustomer,
  RevisionReasonChangedByUser,
  RevisionReasonChangedByCustomer,
  RevisionStickertChangedByUser,
  OrderCreatedByUser = 400,
  OrderCreatedByCustomer,
  WriterOrderAssignedByUser,
  OrderProgressStatusChangedByUser,
  OrderProgressStatusChangedByCustomer,
  OrderAppearanceStatusChangedByUser,
  OrderAppearanceStatusChangedByCustomer,
  OrderFileChangedByUser,
  OrderFileChangedByWriter,
  OrderFileChangedByCustomer,
  OrderStickerChangedByUser,
  OrderExternalCredentialChangedByUser,
  OrderExternalCredentialChangedByCustomer,
  OrderSandboxSnapshotCreatedByUser,
  OrderSandboxSnapshotCreatedByCustomer,
  OrderSandboxSnapshotUpdatedByUser,
  OrderSandboxSnapshotUpdatedByCustomer,
  OrderSandboxSnapshotEnforcedByUser,
  OrderSandboxSnapshotEnforcedByCustomer,

  OrderSandboxSnapshotPrimaryStatusChangedByUser,
  OrderSandboxSnapshotPrimaryStatusChangedByCustomer,
  OrderMainSnapshotUpdatedByUser,
  OrderMainSnapshotUpdatedByCustomer,
  OrderMergeableComponentCreatedByUser,
  OrderMergeableComponentCreatedByCustomer,
  OrderToggleVerificationStatusByUser,
  OrderPaymentReceived = 499,
  SalesPipelineStickerManipulationByUser = 500,
}

export type WSMessage<T> = {
  data: T;
  event: { id: WSEventTypes; name: string };
};

export enum WSCloseCodes {
  Normal = 4000, // unmounted
  Offline,
  Restore,
  GoneAway, // pong not received 3 times
}

export interface IWebSocketInstanceAPI {
  close: (code: WSCloseCodes, message?: string) => void;
  getInstance: () => WebSocket | undefined;
}

export interface IWebSocketInstanceOptions {
  path: string;
  tokens: ITokenPair;
  onStatusChange: (status: WSReadyState) => void;
  onMessage: (e: MessageEvent<any>) => any;

  onOpen?: (e: Event) => void;
  onClose?: (e: CloseEvent) => void;
  onError?: (e: Event) => void;
}

export type TWSEventListener = (wsEvent: MessageEvent<any>) => void;
export type TWSMessageHandler<T = WSMessage<any>> = (eventData: T) => void;

export type WebsocketCreatePayload = {
  token: string;
  notify?: boolean;
};

export type WSMessageEventDetail = {
  path: string;
  data: MessageEvent;
};

export type WSStatusChangeEventDetail = {
  path: string;
  status: WSReadyState;
};

export type WSConnectionErrorEventDetail = {
  path: string;
  data: Event;
};

export type WSConnectionClosedEventDetail = {
  path: string;
  data: CloseEvent;
};

export type WSConnectionOpenEventDetail = {
  path: string;
  data: Event;
};

export enum WSUserEvents {
  Ping = 1,
  Pong,
  Issue,
  IssueMessage,
  IssueTag,
  Ticket = 6,
  TicketMessage = 7,
  RevisionCreated = 8,
  OrderPaid = 9,
  SalesPipeline,
}

export type WSEventType<T extends WSUserEvents, S> = {
  data: S;
  event: {
    id: T;
    name: string;
  };
};

export type SWIdName = {
  id: number;
  name?: string;
};

type SWCustomer = {
  id: number;
  firstName?: string;
  lastName?: string;
};

export type WSTicketMessageData = {
  author?: SWIdName;
  customer?: SWCustomer;
  dateAdded: string;
  id: number;
  idEventType: WSEventTypeIds;
  idParentMessage?: number;
  idStatus: number;
  idThreadMessage?: number;
  idTicket: number;
  idVisibility: number;
  text: string;
  title?: string;
  writer?: SWIdName;
};

export type WSSalesPipelineData = {
  id: number;
  idEventType: WSEventTypeIds;
};

export type WSTicketData = {
  author?: SWIdName;
  customer?: SWCustomer;
  dateAdded: string;
  id: number;
  dateDue?: string;
  dateUpdated?: string;
  idAssignee?: SWIdName;
  idEventType: WSEventTypeIds;
  idGrading?: number;
  idOrder: number;
  idOrderSnapshot?: number;
  idOrigin: TicketOrigins;
  idVisibility: number;
  idRevision?: number;
  idSource?: number;
  idStatus?: TicketStatuses;
  idWriterOrder?: number;
  text: string;
  title?: string;
  writer?: SWIdName;
};

export type WSTicketMessageEvent = WSEventType<WSUserEvents.TicketMessage, WSTicketMessageData>;

export type WSTicketEvent = WSEventType<WSUserEvents.Ticket, WSTicketData>;

export type WSRevisionEvent = WSEventType<WSUserEvents.RevisionCreated, WSTicketMessageData>;

export type WSOrderPaidEvent = WSEventType<WSUserEvents.OrderPaid, WSTicketMessageData>;

export type WSSalesPipelineStickerEvent = WSEventType<WSUserEvents.SalesPipeline, WSSalesPipelineData>;

export type WSAnyMessage = WSTicketMessageEvent | WSTicketEvent | WSRevisionEvent | WSOrderPaidEvent;
