import { CSSProperties, MouseEventHandler, ReactNode } from 'react';

interface WrapperProps {
  children: ReactNode;
  className?: string;
  flow?: 'column' | 'row' | 'column-reverse' | 'row-reverse';
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch';
  justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';
  gap?: number | string;
  inline?: boolean;
  wrap?: boolean;
  width?: number;
  onClick?: MouseEventHandler<HTMLDivElement>;
  style?: CSSProperties;
}

/**
 * Flex (use for content with sidebar)
 * @param children
 * @param gap 16px as default
 * @param flow row as default
 * @param wrap nowrap as default
 * @param inline inline-flex
 * @param onClick onClick cb
 * @param alignItems
 * @param justifyContent
 * @param width (also set minWidth too)
 * @param className
 * @param style (extra props)
 */
export const Flex = ({
  children,
  gap = 16,
  flow = 'row',
  wrap,
  inline,
  width,
  alignItems,
  justifyContent,
  className,
  onClick,
  style,
}: WrapperProps) => (
  <div
    style={{
      display: inline ? 'inline-flex' : 'flex',
      flexFlow: `${flow} ${wrap ? 'wrap' : 'nowrap'}`,
      gap,
      width,
      minWidth: width,
      alignItems,
      justifyContent,
      ...style,
    }}
    className={className}
    onClick={onClick}
  >
    {children}
  </div>
);
