import { Badge } from 'antd';
import { BadgeProps } from 'antd/lib/badge';
import cn from 'classnames';

// Styles
import s from './BadgeCounter.module.less';

export interface BadgeCounterProps extends BadgeProps {
  text?: string;
  count: number | undefined;
  type?: 'default' | 'alert';
}

export const BadgeCounter: FC<BadgeCounterProps> = ({ text, className, type = 'default', ...props }) => (
  <>
    {text && <span className={s.prefix}>{text}</span>}
    <Badge overflowCount={100000} className={cn(s.wrapper, s[type], className)} {...props} />
  </>
);
