import { useAppContext } from '@/app/contexts/AppContext';
import cn from 'classnames';
import s from './Logo.module.less';
import { useCallback } from 'react';
import { trackEvent } from '@/helpers/gtag';

type Props = {
  alt?: string;
  className?: string;
};

export const Logo = ({ alt, className }: Props) => {
  const { config } = useAppContext();

  const handleLogoClick = useCallback(() => trackEvent({ category: 'Dashboard', action: 'Open site' }), []);

  return (
    <div className={cn(s.wrapper, className)}>
      <a href={config.site.url || '/'} onClick={handleLogoClick}>
        <img src={config.assets.logo} className={s.logo} alt={alt || 'logo'} />
      </a>
    </div>
  );
};
