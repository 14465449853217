import { useState } from 'react';
import { useAppSelector, searchSelectorByView } from './helpers';
import { GeneralSearchParams } from './types';

export const useSearchPageParams = <V extends string, D extends GeneralSearchParams>(idView: V, defaultParams: D, overrides?: D) => {
  const savedParams = useAppSelector(searchSelectorByView(idView));

  const [params, setParams] = useState<D>({
    page: 1,
    ...(savedParams || defaultParams),
    ...(overrides || {}),
  } as D);

  return {
    params,
    setParams,
  };
};
