import { USER_DATA_KEY } from '@/config/constants';
import { WSMessage } from '@/shared/features/websockets/types/websockets';
import { msg } from '@/shared/helpers/msg';
import { RTKQQueryHandler } from '@/shared/types/rtkq';
import { RootState, store } from '@/store/store';
import { createEntityAdapter, EntityState } from '@reduxjs/toolkit';
import { MaybeDrafted } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import { pick } from 'lodash';
import {
  CommonMessage,
  CommonMessageCreatePayload,
  CommonTicketMessagesReadRequest,
  CommonTicketMessagesReadResponse,
  CommonTicketPatchMessage,
  WSCommonMessage,
} from '../types/messages';
import { scrollToMessageById } from './messengerHelpers';

/**
 * Adapter CommonMessage for transform response
 * Helps manage cache
 * Adapter: https://redux-toolkit.js.org/rtk-query/usage/streaming-updates#websocket-chat-api-with-a-transformed-response-shape
 * Manual cache update: https://redux-toolkit.js.org/rtk-query/usage/manual-cache-updates
 */
export const messagesAdapter = createEntityAdapter<CommonMessage>();

// Dependency injection -> avoid circular dependency -> coz: messengerService.ts -> messengerApi.ts -> messengerService.ts
const getApiUtils = async () => await import('../api/messenger').then(({ messengerApi }) => messengerApi.util); // better than provide type "any"

// Primary key is getTicketMessages -> same as endpoint name
const ENDPOINT_CACHE_KEY = 'getTicketMessages';

/**
 * [PERFORMANCE] Transform arrays to dictionary with messagesAdapter
 */
export function transformMessagesToDict(response: CommonMessage[]) {
  return messagesAdapter.setAll(messagesAdapter.getInitialState(), response);
}

/**
 * [WS] Update / Insert incoming ws message to current message board
 */
export async function upsertIncomingMessage(idTarget: number, e: MessageEvent<any>) {
  console.log('[ws] upsertIncomingMessage');
  const wsEventPayload = JSON.parse(e.data) as WSMessage<WSCommonMessage>;

  const { data: message } = wsEventPayload;

  if (message.idTicket !== idTarget) return;

  store.dispatch(
    (await getApiUtils()).updateQueryData(ENDPOINT_CACHE_KEY, idTarget, (draft) => {
      updateMessagesRecipe(draft, message);
    }),
  );
}

/**
 * [PERFORMANCE] Add new message to message-board before WS event
 */
export const handleOutgoingOwnMessage: RTKQQueryHandler<CommonMessageCreatePayload, CommonMessage> = async (args, api) => {
  try {
    const response = await api.queryFulfilled; // response 201
    const sentMessage = response?.data;
    if (!sentMessage) return; // bad response

    const idThreadMessage = args.idParentMessage;
    const { auth } = store.getState() as RootState; // store instead of api -> coz: ts overlaps issue
    // [OPTIMIZATION] pick only required fields -> takes less memory
    const user = pick(auth.user, ['id', 'publicName', 'firstName', 'lastName']); // todo: received from backend
    const ownNewMessage = { [USER_DATA_KEY]: user, ...args, ...sentMessage, idThreadMessage, isRead: true }; // todo: received from backend

    api.dispatch(
      (await getApiUtils()).updateQueryData(ENDPOINT_CACHE_KEY, args.idTicket, (draft) => {
        updateMessagesRecipe(draft, ownNewMessage);
      }),
    );

    // Scroll to thread message
    idThreadMessage && sentMessage.id && scrollToMessageById(sentMessage.id);
  } catch (err) {
    msg.error('Message not sent. Please check connection and try again later.');
    console.error('handleOutgoingOwnMessage', err);
    return; // failed
  }
};

/**
 * [WS] Update message board (common logic for ticketMessages & issueMessages)
 */
export function updateMessagesRecipe(draft: MaybeDrafted<EntityState<CommonMessage>>, message: CommonMessage | undefined) {
  if (!message) return;

  const { getSelectors, upsertOne, addMany, getInitialState } = messagesAdapter; // [] -> { ids: [], entities: [{},{}] }

  // check message parent (replied message)
  const parentMessage = getSelectors().selectById(draft, message?.idParentMessage as number); // get parent message

  if (parentMessage) {
    // child message
    const _selectThread = addMany(getInitialState(), parentMessage?.threadMessages || []); // select parent
    const _updatedThread = upsertOne(_selectThread, message); // patch or insert message
    const threadMessages = getSelectors().selectAll(_updatedThread); // normalize data {} => []
    upsertOne(draft, { ...parentMessage, threadMessages }); // commit changes
  } else {
    // orphan message
    upsertOne(draft, message); // insert message in main thread
  }
}

/**
 * [PERFORMANCE] Process message patch before received by WS
 */
export const handleMessagePatch: RTKQQueryHandler<CommonTicketPatchMessage, CommonMessage> = async (args, api) => {
  try {
    const res = await api.queryFulfilled;
    if (!res?.data) return; // bad response

    api.dispatch(
      (await getApiUtils()).updateQueryData(ENDPOINT_CACHE_KEY, args.idTicket, (draft) => {
        updateMessagesRecipe(draft, res.data);
      }),
    );
  } catch (err) {
    console.error('handleMessagePatch', err);
  }
};

// Update read messages -> response -> ids[] of patched messages
export const handleMessagesRead: RTKQQueryHandler<CommonTicketMessagesReadRequest, CommonTicketMessagesReadResponse> = async (
  args,
  api,
) => {
  try {
    const res = await api.queryFulfilled; // wait for response
    const readMessagesIds = res?.data || []; // ids of patched messages
    if (!readMessagesIds.length) return; // bad response

    // Update message board -> mark messages from response as read
    api.dispatch(
      (await getApiUtils()).updateQueryData(ENDPOINT_CACHE_KEY, args.idTicket, (draft) => {
        // Assuming draft.entities is an object with message IDs as keys
        if (!draft.entities) return;

        readMessagesIds.forEach((id) => {
          if (!draft?.entities?.[id]) return;
          draft.entities[id]!.isRead = true; // mark as read
        });
      }),
    );
  } catch (err) {
    console.error('handleMessagesRead', err);
  }
};
