import { Button } from '@/components/common/button/Button';
import { Flex } from '@/components/common/layout/Flex';
import { Navigation } from '@/components/common/layout/Navigation';
import { AppLink } from '@/components/common/link/AppLink';
import { useProfile } from '@/shared/hooks/useProfile';
import { ProfileAvatar } from '@feature/profile/ProfileAvatar';
import { Drawer } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import s from './HeaderMobileMenu.module.less';

export const HeaderMobileMenu = () => {
  const { t } = useTranslation();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const profile = useProfile();
  const closeDrawer = () => setDrawerVisible(false);

  const hasName = !!(profile.firstName || profile.lastName);

  return (
    <>
      <Button className={s.burger} onClick={() => setDrawerVisible(true)} icon="IcoRBars" modification="text" />
      <Drawer
        width={300}
        placement="right"
        visible={drawerVisible}
        title="Menu"
        onClose={() => setDrawerVisible(false)}
        className={s.drawer}
      >
        <div className={s.wrapper}>
          <AppLink to={'/order'} className={s.orderBtn} onClick={closeDrawer}>
            <Button size="large" icon={'IcoRPlus'} modification="primaryOutlined">
              {t('header.orderBtn')}
            </Button>
          </AppLink>

          <Navigation collapsed={false} onMenuClick={closeDrawer} />
          <AppLink to={'/profile'} className={s.user} onClick={closeDrawer}>
            <Flex alignItems={'center'} gap={12}>
              <ProfileAvatar fallback={<Button icon={'IcoUserOutlined'} modification={'gradient'} circle />} />

              <div className={s.userName}>
                {hasName ? (
                  <>
                    {profile.firstName ?? ''} {profile.lastName ?? ''}
                  </>
                ) : (
                  'Anonymous'
                )}
              </div>
            </Flex>
          </AppLink>
        </div>
      </Drawer>
    </>
  );
};
