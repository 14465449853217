import { FeatureType, getURL } from '@/config/features';
import { FC, ReactNode } from 'react';
import { Link, LinkProps } from 'react-router-dom';

// Styles
type FeatureLinkProps = {
  feature: FeatureType;
  id?: number;
  text?: ReactNode;
  qs?: string;
} & Omit<LinkProps, 'to' | 'id'>;

export const FeatureLink: FC<FeatureLinkProps> = ({ feature, id, text, children, className, qs, ...props }) => {
  const url = getURL(feature, id);
  return (
    <Link className={className} to={qs ? `${url}?${qs}` : url} {...props}>
      {children || text || id || url}
    </Link>
  );
};
