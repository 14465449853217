import { FEATURE } from '@/config/features';
import { emptyApi, getTags } from '@/store/emptyApi';
import {
  IOwnFile,
  IOwnFileHideRequest,
  IOwnFileRemoveRequest,
  IOwnFileSearchItem,
  IOwnFileSearchParams,
  IOwnFileShowRequest,
  IOwnFileUpdateRequest,
  IOwnFileUploadRequest,
} from '@/types/file/ownFile';
import { SearchView } from '@/types/search';

const { type, path } = FEATURE.OwnFile;
const { invalidatesTags, providesTags } = getTags(type, [FEATURE.Order.type, FEATURE.Ticket.type, FEATURE.Revision.type]);

const injectedRtkApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    searchOwnFiles: build.query<SearchView<IOwnFileSearchItem>, IOwnFileSearchParams>({
      query: (params) => ({ url: `${path}`, params }),
      providesTags: [{ type, id: 'search' }],
    }),
    getOwnFile: build.query<IOwnFile, number>({
      query: (id) => ({ url: `${path}/${id}` }),
      providesTags,
    }),
    uploadOwnFile: build.mutation<IOwnFile, IOwnFileUploadRequest>({
      query: (body) => ({ url: `${path}`, method: 'POST', body }),
    }),
    updateOwnFile: build.mutation<IOwnFile, IOwnFileUpdateRequest>({
      query: ({ id, body }) => ({ url: `${path}/${id}`, method: 'PUT', body }),
      invalidatesTags,
    }),
    showOwnFile: build.mutation<IOwnFile, IOwnFileShowRequest>({
      query: ({ id, body }) => ({ url: `${path}/${id}/appearance/show`, method: 'PATCH', body }),
      invalidatesTags,
    }),
    hideOwnFile: build.mutation<IOwnFile, IOwnFileHideRequest>({
      query: ({ id, body }) => ({ url: `${path}/${id}/appearance/hide`, method: 'PATCH', body }),
      invalidatesTags,
    }),
    removeOwnFile: build.mutation<IOwnFile, IOwnFileRemoveRequest>({
      query: ({ id, body }) => ({ url: `${path}/${id}/appearance/remove`, method: 'DELETE', body }),
      invalidatesTags,
    }),
  }),
  overrideExisting: false,
});

export const {
  useSearchOwnFilesQuery,
  useGetOwnFileQuery,
  useUploadOwnFileMutation,
  useUpdateOwnFileMutation,
  useShowOwnFileMutation,
  useHideOwnFileMutation,
  useRemoveOwnFileMutation,
} = injectedRtkApi;
