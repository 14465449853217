import { useCallback, useEffect, useState } from 'react';

/**
 * Check document visibility
 * @returns true if visibility === 'visible'
 */
export const useDocumentVisibility = (): boolean => {
  const [isDocVisible, setDocVisible] = useState(true);

  const handleDocVisibilityChange = useCallback(() => {
    setDocVisible(document.visibilityState === 'visible');
  }, []);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleDocVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleDocVisibilityChange);
    };
  }, [handleDocVisibilityChange]);

  // sdcdscscds

  return isDocVisible;
};
