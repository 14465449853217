import { FeatureLink } from '@/components/common/FeatureLink';
import { IconSvg } from '@/components/common/icons/IconSvg';
import { Flex } from '@/components/common/layout/Flex';
import { Trunc } from '@/components/common/Trunc';
import { FeatureType, SITE_MENUS } from '@/config/features';
import { useBreakpoints } from '@/hooks/breakpoints/useBreakpoints';
import { Tooltip } from 'antd';
import cn from 'classnames';
import { useLocation } from 'react-router-dom';

import s from './Navigation.module.less';

type Props = {
  collapsed?: boolean;
  onMenuClick?: (type: FeatureType) => void;
  className?: string;
}

export const Navigation = ({ onMenuClick, collapsed, className }: Props) => {

  const { pathname } = useLocation();
  const { isMd } = useBreakpoints();

  const handleMenuClick = (type: FeatureType) => {
    onMenuClick?.(type);
  };

  return (
    <div className={cn(s.wrapper, className, {
      [s.collapsed]: collapsed,
    })}>
      {SITE_MENUS.map(({ name, list }) => (
        <div key={name} className={s.menu}>
          <div className={s.menuName}>
            {name}
          </div>
          <ul className={s.menuList}>
            {list.map(({ title, type, path, menu }) => (
              <li key={type} className={cn(s.menuItem, {
                [s.active]: pathname.includes(path),
              })}>
                <Tooltip title={!isMd && (menu?.heading ?? title)} placement={'right'}>
                  <FeatureLink feature={type} className={s.menuItemLink} onClick={() => handleMenuClick(type)}>
                    <Flex alignItems={'center'} gap={0} justifyContent={collapsed ? 'center' : 'flex-start'}>
                      <IconSvg size={20} icon={menu?.icon || 'IcoRFileImage'} />
                      <div className={s.menuItemText}><Trunc text={menu?.heading ?? title} max={20} /></div>
                    </Flex>
                  </FeatureLink>
                </Tooltip>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};