import { ICustomer } from '@/shared/types/participants';
import { RootState } from '@/store/store';
import { ITokenPair } from '@/types/auth';
import { IEmail } from '@/types/email';
import { IPhone } from '@/types/phone';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TAuthUser = ICustomer & {
  primaryEmail?: IEmail;
  primaryPhone?: IPhone;
};

export interface AuthState {
  isLoggedIn: boolean;
  user: TAuthUser;
  tokens: ITokenPair;
  debug: string;
}

export const authInitialState: AuthState = {
  isLoggedIn: false,
  user: {
    id: 0,
    firstName: 'Customer',
    lastName: '-',
  },
  tokens: {
    refreshToken: '',
    accessToken: '',
  },
  debug: 'important data', // TODO: missed key! why?
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: authInitialState,
  reducers: {
    setUser: (state: AuthState, { payload }: PayloadAction<ICustomer>) => {
      state.user = payload;
    },
    setTokens: (state: AuthState, { payload }: PayloadAction<ITokenPair>) => {
      state.tokens = payload;
      state.isLoggedIn = true;
    },
    refreshAccessToken: (state: AuthState, { payload }: PayloadAction<{ accessToken: string }>) => {
      state.tokens.accessToken = payload.accessToken;
    },
    logout: () => authInitialState,
  },
});

export default authSlice.reducer;

export const { setTokens, setUser, refreshAccessToken, logout } = authSlice.actions;

// Selectors
export const selectAuth = ({ auth }: RootState) => auth;
export const selectIsLoggedIn = ({ auth }: RootState) => auth.isLoggedIn;
export const selectUserId = ({ auth }: RootState) => auth.user.id;

export const tokensSelector = ({ auth }: RootState) => auth.tokens;
