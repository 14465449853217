import { AppProvider } from '@/app/contexts/AppProvider';
import { GuestRoutes } from '@/app/routes/GuestRoutes';
import { UserRoutes } from '@/app/routes/UserRoutes';
import { AppBaseLayout } from '@/components/common/layout/AppBaseLayout';
import { SpinFullscreen } from '@/components/common/spin/SpinFullscreen';
import { ErrorBoundary } from '@/shared/components/error/ErrorBoundary';
import { useAuth } from '@/store/auth/authHooks';
import { ChatwootWidget } from '@feature/chat/ChatwootWidget';
import { GoogleAnalytics4 } from '@feature/ga/GoogleAnalytics4';
import { HotjarWidget } from '@feature/hotjar/HotjarWidget';
import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useWS } from '@/hooks/websockets';

const OrderFormPage = lazy(() => import('@/components/pages/OrderFormPage'));
const Auth = lazy(() => import('@feature/auth/Auth'));
const AuthResetPassword = lazy(() => import('@feature/auth/AuthResetPassword'));
const AuthReportUnauthorisedAccessRequest = lazy(() => import('@feature/auth/AuthReportUnauthorisedAccessRequest'));

function App() {
  const { isLoggedIn } = useAuth();

  useWS();

  return (
    <AppProvider>
      <AppBaseLayout>
        <ErrorBoundary>
          <Suspense fallback={<SpinFullscreen />}>
            <Routes>
              <Route path={'/order'} element={<OrderFormPage />} />
              <Route element={<Auth />}>
                <Route path={'/reset-password/:magicUuid'} element={<AuthResetPassword />} />
                <Route path={'/auth-report/:magicUuid'} element={<AuthReportUnauthorisedAccessRequest />} />
              </Route>
              <Route path={'*'} element={isLoggedIn ? <UserRoutes /> : <GuestRoutes />} />
            </Routes>
          </Suspense>
        </ErrorBoundary>
      </AppBaseLayout>

      <ChatwootWidget />

      <GoogleAnalytics4 />

      <HotjarWidget />
    </AppProvider>
  );
}

export default App;
