import {
  WSCloseCodes,
  WSConnectionClosedEventDetail,
  WSConnectionErrorEventDetail,
  WSConnectionOpenEventDetail,
} from '@/shared/features/websockets/types/websockets';
import { AppWebsocketConnection } from '@/shared/features/websockets/classes/AppWebsocketConnection';

// DEBUG!!!!
// let date = 0;
//
// const intervals: number[] = [];

export class AppWebSocketManager {
  protected connections: Record<string, AppWebsocketConnection> = {};
  protected token = '';
  protected statuses: Record<
    string,
    {
      ok: boolean;
      retrying: boolean;
    }
  > = {};
  public init() {
    this.setupNetworkEventListeners();
    this.setupCloseErrorEventListeners();
  }

  public addToken(token: string) {
    if (token === this.token) return;

    this.token = token;
    this.closeAllConnections(WSCloseCodes.Normal);
    this.initAllConnections();
  }

  public removeToken() {
    this.token = '';
    this.closeAllConnections(WSCloseCodes.Normal);
  }

  public addConnection(path: string, create?: boolean) {
    if (path in this.connections) return this.connections[path];

    const conn = new AppWebsocketConnection(path);

    this.connections[path] = conn;

    if (create) {
      conn.create({ token: this.token });
    }
  }

  public initAllConnections(notify?: boolean) {
    Object.values(this.connections).forEach((conn) => {
      conn.create({
        token: this.token,
        notify,
      });
    });
  }

  public closeAllConnections(code: WSCloseCodes, reason?: string) {
    Object.values(this.connections).forEach((conn) => {
      conn.close(code, reason);
    });
  }

  public getConnection(path: string) {
    return this.connections[path];
  }

  // always reconnect
  protected setupCloseErrorEventListeners() {
    const handleCloseAndError = (({ detail }: CustomEvent<WSConnectionClosedEventDetail | WSConnectionErrorEventDetail>) => {
      const { path } = detail;
      const handleTimeout = () =>
        setTimeout(() => {
          // checking for token
          if (!this.token) return;
          // stop if status is ok
          if (this.statuses[path].ok) return;

          this.getConnection(path).create({ token: this.token });
          this.statuses[path].retrying = false;

          // DEBUG
          // if (!date) {
          //   date = new Date().getTime();
          // } else {
          //   const now = new Date().getTime();
          //   intervals.push(new Date().getTime() - date);
          //   console.log('ELLAPSED', intervals);
          //   date = now;
          // }
        }, 1000);
      if (this.statuses[path]?.retrying) return;
      this.statuses[path] = {
        ok: false,
        retrying: true,
      };

      handleTimeout();
    }) as EventListener;

    const handleOpen = (({ detail }: CustomEvent<WSConnectionOpenEventDetail>) => {
      if (this.statuses[detail.path]) {
        this.statuses[detail.path].ok = true;
      } else {
        this.statuses[detail.path] = {
          ok: true,
          retrying: false,
        };
      }
    }) as EventListener;

    document.addEventListener('wsConnectionClose', handleCloseAndError);
    document.addEventListener('wsConnectionError', handleCloseAndError);
    document.addEventListener('wsConnectionOpen', handleOpen);
  }

  protected setupNetworkEventListeners() {
    window.addEventListener('offline', () => {
      if (!this.token) return;
      Object.values(this.connections).forEach((conn) => {
        conn.create({ token: this.token, notify: true });
      });
    });
    window.addEventListener('online', () => {
      Object.values(this.connections).forEach((conn) => {
        conn.close(WSCloseCodes.Offline, 'Now you are offline');
      });
    });
  }
}
