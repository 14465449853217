type BaseExtraFeatureSetting = {
  id: number;
  customerName?: string;
  customerDescription?: string;
  flatSurcharge?: number;
  percentageSurcharge?: number;
};

export type PreferredWriterSetting = BaseExtraFeatureSetting;
export type PremiumWriterSetting = BaseExtraFeatureSetting;
export type ExtraQualityCheckSetting = BaseExtraFeatureSetting & {
  freeCheckMinPrice?: number;
};

export type OriginalityReportSetting = BaseExtraFeatureSetting & {
  freeCheckMinPrice?: number;
};

export type AIReportSetting = BaseExtraFeatureSetting & {
  freeServiceMinPrice?: number;
};

export type DraftFeatureSetting = BaseExtraFeatureSetting & {
  freeServiceMinPrice?: number;
  minDeadline?: number;
  minWords?: number;
};

export enum ExtraFeatureTypes {
  PremiumWriter = 1,
  OriginalityReport,
  ExtraQualityCheck,
  PreferredWriter,
  AIReport,
  DraftFeature,
}

export type ExtraFeatureSetting<T, ExtraFeatureTypes> = T & {
  // only internal use
  idExtraFeatureType: ExtraFeatureTypes;
};

export type ExtraFeatureAnySetting =
  | ExtraFeatureSetting<PremiumWriterSetting, ExtraFeatureTypes.PremiumWriter>
  | ExtraFeatureSetting<PreferredWriterSetting, ExtraFeatureTypes.PreferredWriter>
  | ExtraFeatureSetting<ExtraQualityCheckSetting, ExtraFeatureTypes.ExtraQualityCheck>
  | ExtraFeatureSetting<OriginalityReportSetting, ExtraFeatureTypes.OriginalityReport>
  | ExtraFeatureSetting<AIReportSetting, ExtraFeatureTypes.AIReport>
  | ExtraFeatureSetting<DraftFeatureSetting, ExtraFeatureTypes.DraftFeature>;
