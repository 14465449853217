export const suppressAntdWarnings = () => {
  const logError = console.error; //
  console.error = (...args) => {
    const [firstArg] = args;
    if (String(args?.[0]).match(/Warning:\s\[antd:/)) {
      const [payloadMessage] = String(firstArg).split('\n');
      console.log('👋', payloadMessage);
    } else {
      logError(...args);
    }
  };
};
