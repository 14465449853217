import { logout } from '@/store/auth/authSlice';
import { restoreOrderState } from '@/store/order/orderSlice';
import { AppDispatch } from '@/store/store';
import { resetLocalPreferences } from '@/store/localPreferences/localPreferenceSlice';

export const authLogout = (api) => (dispatch: AppDispatch) => {
  console.log('authLogout > restoreOrderState & logout');
  dispatch(restoreOrderState());
  dispatch(resetLocalPreferences());
  dispatch(logout());

  // https://github.com/reduxjs/redux-toolkit/issues/3119
  // Works with version 1.8.6. Don't upgrade toolkit version until bug fixed
  dispatch(api.util.resetApiState());
};
