import {
  Label,
  LabelOptions,
  SearchEngineLabels,
  ProfilePreferencesWithSearch,
  SearchDictInterface,
  RootState,
  UpdatePreferencesParams,
  SearchDict,
  AddSearchPreferenceParams,
} from './types';
import { lowerCase, omit, pick, upperFirst } from 'lodash';
import { TypedUseSelectorHook, useSelector } from 'react-redux';

const defaultLabels: SearchEngineLabels = {
  filters: 'Filters',
  close: 'Close',
  search: 'Search',
  totalItems: (options) => {
    if (typeof options?.count === 'number') {
      return `Total ${options.count} item${options.count > 1 ? 's' : ''}`;
    }
    return '';
  },
  resetToDefaults: 'Reset to defaults',
  clearAllFilters: 'Clear all filters',
  sortPreferences: 'Sort preferences',
  by: 'By',
  sortOrder: 'Order',
  sortParams: (options) => {
    if (options?.name) {
      return upperFirst(lowerCase(options.name));
    }
    return '';
  },
  dateParams: (options) => {
    if (options?.name) {
      return upperFirst(lowerCase(options.name));
    }
    return '';
  },
  from: 'From',
  to: 'To',
  asc: 'ASC',
  desc: 'DESC',
  showExtendedControls: 'Show extended search controls',
  hideExtendedControls: 'Hide extended search controls',
};

export class LabelsResolver {
  protected readonly labels: SearchEngineLabels;

  constructor(labels?: SearchEngineLabels) {
    this.labels = labels || {};
  }

  protected resolveLabel(value: Label, options?: LabelOptions): string {
    return typeof value === 'string' ? value : typeof value === 'function' ? value(options) : '';
  }

  resolve(label: keyof SearchEngineLabels, options?: LabelOptions): string {
    const targetLabel = this.labels[label] || defaultLabels[label];

    return targetLabel ? this.resolveLabel(targetLabel as Label, options) : '';
  }
}

const isValidValue = (value: any) => {
  if (typeof value === 'boolean') return true;

  if (Array.isArray(value)) {
    return !!value.length;
  }

  if (!!value) return true;
};

export const getAppliedFiltersCount = (params: object, protectedFields?: (string | number | symbol)[]) => {
  const ignoredValues = ['sortBy', 'sortType', 'size', 'page'];

  const filteredKeys = Object.entries(params)
    .filter(([key, value]) => !protectedFields?.includes(key) && !ignoredValues.includes(key) && isValidValue(value))
    .map(([key]) => key)
    .sort();

  const filteredDateKeys = filteredKeys.filter((key) => {
    const keyName = key.replace(/(From|To)$/, '');
    return key.endsWith('From') ? !filteredKeys.includes(`${keyName}To`) : true;
  });

  return filteredDateKeys.length;
};

export const getPreferencesWithoutPages = (preferences: ProfilePreferencesWithSearch) => {
  const updatedSearch: SearchDictInterface = {};

  if (preferences.search) {
    Object.keys(preferences.search).forEach((key) => {
      updatedSearch[key] = omit(preferences?.search?.[key] || {}, 'page');
    });
  }
  return {
    ...preferences,
    search: updatedSearch,
  };
};

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// clean up outdated views
export const getStateWithUpdatedSearchPreferences = ({ preferences, views }: UpdatePreferencesParams) => ({
  ...preferences,
  search: { ...pick(preferences?.search, views) },
});

export const getSearchPreferencesWithNewKey = ({ key, value, views, viewConfigs, preferences }: AddSearchPreferenceParams) => {
  const blackListedValues = [...(viewConfigs?.find(({ id }) => id === key)?.protectedFields || [])];
  // clean up outdated views
  const updatedState = pick(preferences, views);

  updatedState[key] = { ...omit(value, blackListedValues) };
  return updatedState;
};

export const searchSelectorByView =
  (view: string) =>
  (state: RootState): SearchDict =>
    state.localPreferences?.search?.[view];
