import { useDocumentVisibility } from './useDocumentVisibility';

/**
 * Smart Polling (auto refetch) – only if active document (tab) is visible
 * Example of use:
 *    const { pollingInterval } = useSmartPolling(10, wss.readyState === WSReadyState.Open);
 *    const { data } = useQuery({}, { pollingInterval });
 * @param {number} seconds
 * @param {boolean} extraCondition
 * @returns {{isDocVisible: boolean, pollingInterval: number | undefined}}
 */
export const useSmartPolling = (seconds: number, extraCondition = true) => {
  const isDocVisible = useDocumentVisibility();
  return {
    isDocVisible,
    pollingInterval: isDocVisible && extraCondition ? seconds * 1000 : undefined,
  };
};
