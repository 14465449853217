import { ICommonUser } from '@/shared/types/participants';
import { AssociationBulkMode, ILogMessage, Patch } from '@/types/common/api';
import { Association } from '@/types/common/associations';
import { Currencies } from '@/types/common/currency';
import { TCustomerExternalCredential } from '@/types/customerExternalCredentials';
import {
  AIReportSetting,
  DraftFeatureSetting,
  ExtraQualityCheckSetting,
  OriginalityReportSetting,
  PreferredWriterSetting,
  PremiumWriterSetting,
} from '@/types/extraFeature';
import { TFeedbackExtended } from '@/types/feedbacks';
import { IUserFile, IWriterFile } from '@/types/file/file';
import { IOwnFile } from '@/types/file/ownFile';
import {
  IAcademicDiscipline,
  IAcademicLevel,
  ICitationStyle,
  ILegacyExtraFeature,
  ILineSpacing,
  IPaperType,
  IPaperTypesUnit,
  IWorkType,
} from '@/types/orderForm';
import { AssociationOrderPreferredWriter } from '@/types/preferredWriter';
import { IDiscount } from '@/types/priceQuote';
import { IRevision, IRevisionOrder } from '@/types/revision';
import { CommonSearchParams } from '@/types/search';

export enum IOrderProgressStatuses {
  Untouched = 1,
  Pending,
  InWork,
  Submitted,
  Completed,
  Aborted = 7,
  UnableToCommence,
  UnableToProceed,
  Ready,
  UpForGrabs,
}

export enum IOrderAppearanceStatuses {
  Visible = 1,
  Hidden,
  Removed,
}

export enum OrderDataSufficiencyStatuses {
  NotImposed = 1,
  Satisfactory,
  Unsatisfactory,
  Pending,
}

export enum OrderDataConsistencyStatuses {
  NotImposed = 1,
  Satisfactory,
  Unsatisfactory,
  Pending,
}

export enum OrderExtraSnapshotStatuses {
  Draft = 1,
  Requested,
  Pending,
  Discarded,
  Successful,
  Reverted,
}

export type IOrderSnapshot = {
  id: number;
  dateAdded: string;
  dateDue?: string;
  title: string;
  customerName?: string;
  customerDescription?: string;
  details?: string;
  units?: number;
  words?: number;
  sources?: number;
  deadline: number;
  isPrimary?: boolean;
  isSandbox?: boolean;
  idDataConsistencyStatus?: OrderDataConsistencyStatuses;
  idDataSufficiencyStatus?: OrderDataSufficiencyStatuses;
  academicDiscipline?: IAcademicDiscipline;
  customAcademicDiscipline?: string;
  academicLevel: IAcademicLevel;
  citationStyle?: ICitationStyle;
  customCitationStyle?: string;
  discount?: IDiscount;
  lineSpacing?: ILineSpacing;
  paperType: IPaperType;
  paperUnit: IPaperTypesUnit;
  workType: IWorkType;
};

export type IOrderExtraSnapshot = Omit<IOrderSnapshotExtended, 'extraSnapshots'> & {
  idExtraStatus: OrderExtraSnapshotStatuses;
};

export type IOrderSnapshotExtended = IOrderSnapshot & {
  dynamicWords: number;
  dynamicUnits: number;
  dynamicWordsPerHour: number;
  dynamicRealWordsPerHour: number;
  dynamicVolumePrice: number;
  dynamicWorkTypePriceMultiplier: number;
  dynamicAcademicLevelPriceMultiplier: number;
  dynamicPaperTypePriceMultiplier: number;
  dynamicAcademicDisciplinePriceMultiplier: number;
  dynamicBodyPrice: number;
  dynamicDeadlinePercentageSurcharge: number;
  dynamicDeadlineSurcharge: number;
  dynamicWordsPerHourPercentageSurcharge: number;
  dynamicWordsPerHourSurcharge: number;
  dynamicBasicPrice: number;
  dynamicDiscountedAmount: number;
  dynamicSubtotalAmount: number;
  dynamicExtraFeaturesSurcharge: number;
  dynamicTotalPrice: number;
  dynamicExtraQualityCheckSurcharge?: number;
  dynamicOriginalityReportSurcharge?: number;
  dynamicPreferredWriterSurcharge?: number;
  dynamicPremiumWriterSurcharge?: number;
  dynamicAiReportSurcharge?: number;
  dynamicSuccessfulExtraSnapshotsTotalPrice: number;
  dynamicPendingExtraSnapshotsTotalPrice: number;

  incomingTransactions?: IIncomingTransaction[];

  // extraFeatures
  associationExtraFeatures?: IAssociationOrderExtraFeature[];
  isOkWithNonPreferred?: boolean;
  cAssociationPreferredWriters?: AssociationOrderPreferredWriter[];
  preferredWriterSetting?: PreferredWriterSetting;
  extraQualityCheckSetting?: ExtraQualityCheckSetting;
  originalityReportSetting?: OriginalityReportSetting;
  premiumWriterSetting?: PremiumWriterSetting;
  AIReportSetting?: AIReportSetting;
  draftFeatureSetting?: DraftFeatureSetting;

  // Extra snapshots
  extraSnapshots: IOrderExtraSnapshot[];
};

export type IAssociationOrderExtraFeature = Association<
  {
    extraFeature: ILegacyExtraFeature;
    surchargeAmount: number;
  },
  OrderSnapshotExtraFeatureStatuses
>;

export type OrderSnapshotExtraFeatureStatuses = '1' | '2' | '3' | '4' | '5' | '6';

export type IOrder = {
  id: number;
  dateAdded: string;
  dateReady?: string;
  datePaid?: string;
  idProgressStatus: IOrderProgressStatuses;
  idAppearanceStatus?: IOrderAppearanceStatuses;
};

export type IOrderExtended = IOrder & {
  sandboxSnapshot?: IOrderSnapshotExtended | null;
  snapshot?: IOrderSnapshotExtended | null;
  dynamicAmountCredited: number;
  dynamicBalance?: number;
  dynamicRealBalance?: number;
  incomingTransactions?: IIncomingTransaction[];
  activeRevision?: IRevisionOrder;
  primaryFeedback?: TFeedbackExtended;
};

export type IOrderSearchItem = IOrderExtended & {
  mainWriterOrder?: Omit<IOrderMainWriterOrder, 'associationDraftWriterFiles' | 'associationWriterFiles'>;
};

export type IOrderDetails = IOrderExtended & {
  mainWriterOrder?: IOrderMainWriterOrder;
  // snapshots?: IOrderSnapshot[]; // !
  // incomingTransactions?: IIncomingTransaction[]; // !
  // lastPlagcheck?: IPlagCheck; // !
  plagChecks?: IPlagCheck[];
  cAiChecks?: IAICheck[];
  myRevisions?: IRevisionOrder[]; // !

  associationCustomerFiles?: IOrderCustomerFiles[]; // TODO: lazy
  associationExternalCredentials?: TOrderAExternalCredentials[]; // TODO: lazy
};

export type IOrderAppearanceHidePayload = ILogMessage;
export type IOrderAppearanceHideRequest = Patch<IOrderAppearanceUnhidePayload>;

export type IOrderAppearanceUnhidePayload = ILogMessage;
export type IOrderAppearanceUnhideRequest = Patch<IOrderAppearanceUnhidePayload>;

export type IPlagCheck = {
  id: number;
  dateAdded: string;
  customerName: string;
  customerDescription?: string;
  idStatus: PlagCheckStatuses;
  author: User;
  order: IOrder;
  plagCheckItems?: IPlagCheckItem[];
  revision?: IRevision;
};

export type IAICheck = {
  id: number;
  dateAdded: string;
  customerName: string;
  customerDescription?: string;
  idStatus: PlagCheckStatuses;
  author: User;
  order: IOrder;
  cItems?: IAICheckItem[];
  revision?: IRevision;
};

export type PlagCheckStatuses = '1' | '2' | '3' | '4' | '5' | '6';
export type User = {
  name?: string;
};
export type IPlagCheckItem = {
  id: number;
  dateAdded: string;
  customerName?: string;
  customerDescription?: string;
  originalityPercentage?: number;
  idStatus: PlagCheckItemStatuses;
  writerFile?: IWriterFile;
  userFile?: IUserFile;
};

export type IAICheckItem = {
  id: number;
  dateAdded: string;
  customerName?: string;
  customerDescription?: string;
  humanPercentage?: number;
  idStatus: PlagCheckItemStatuses;
  writerFile?: IWriterFile;
  userFile?: IUserFile;
};

export type PlagCheckItemStatuses = '1' | '2' | '3' | '4' | '5' | '6';
// todo: temporary types !!!!

// Search
export type IOrderSortFields =
  | 'dateAdded'
  | 'idAcademicDiscipline'
  | 'idAcademicLevel'
  | 'idPaperType'
  | 'idPaperUnit'
  | 'idWorkType'
  | 'idLineSpacing'
  | 'idCitationStyle'
  | 'dateDue';

export type IOrderSearchParams = CommonSearchParams & {
  id?: number;
  dateAddedFrom?: string;
  dateAddedTo?: string;
  dateInitiatedFrom?: string;
  dateInitiatedTo?: string;
  unitsFrom?: number;
  unitsTo?: number;
  wordsFrom?: number;
  wordsTo?: number;
  sourcesFrom?: number;
  sourcesTo?: number;
  deadlineFrom?: number;
  deadlineTo?: number;
  name?: string;
  idProgressStatus?: IOrderProgressStatuses[];
  idAppearanceStatus?: IOrderAppearanceStatuses[];
  idAcademicDiscipline?: number[];
  idAcademicLevel?: number[];
  idPaperType?: number[];
  idPaperUnit?: number[];
  idWorkType?: number[];
  idCitationStyle?: number[];
  idLineSpacing?: number[];
  sortBy?: IOrderSortFields;
};

// Create
export type IOrderCreatePayload = {
  units?: number;
  words?: number;
  deadline: number;
  idWorkType: number;
  idAcademicLevel: number;
  idPaperType: number;
  idLineSpacing?: number;
  idCitationStyle?: number;
  customCitationStyle?: string;
  idAcademicDiscipline?: number;
  customAcademicDiscipline?: string;
  title: string;
  details: string;
  sources: number;
  discountCode?: string;
  // extra features
  idExtraFeatures?: number[];
  isOkWithNonPreferred?: boolean;
  idPreferredWriters?: number[];
  originalityReport?: boolean;
  extraQualityCheck?: boolean;
  preferredWriter?: boolean;
  premiumWriter?: boolean;
  aiReport?: boolean;
  draftFeature?: boolean;
  selectedFeatures?: number[];
};
export type IOrderCreateResponse = IOrder & {
  sandboxSnapshot: IOrderSnapshot | null;
  snapshot?: IOrderSnapshot | null;
};

export type IOrderSandboxCreatePayload = Partial<IOrderCreatePayload>;
export type IOrderSandboxCreateRequest = { body: IOrderSandboxCreatePayload; id: number };
export type IOrderSandboxCreateResponse = IOrder;

export type IOrderSandboxUpdatePayload = Partial<IOrderCreatePayload>;
export type IOrderSandboxUpdateRequest = { body: IOrderSandboxCreatePayload; id: number };
export type IOrderSandboxUpdateResponse = IOrder;

export type IAssociationOrderCustomerFile = Association<{ customerFile: IOwnFile }, AssociationOrderCustomerFileStatuses>;
export type IOrderCustomerFilePatchRequest = Patch<{ ids: number[] }>;

export type IOrderCustomerExternalCredentialPatchRequest = Patch<{ ids: number[] }>;
export type OrderPreferredWriterPatchRequest = Patch<{ ids: number[] }> & { mode: AssociationBulkMode };

export type IIncomingTransaction = {
  id: number;
  dateAdded: string;
  amount: number;
  idCurrency: Currencies;
  idStatus: IncomingTransactionStatuses;
  amountReversed?: number;
};

// todo: Temporary: need to review

export type IncomingTransactionStatuses = '1' | '2';

export type IOrderCustomerFiles = {
  dateAdded: string;
  idStatus: AssociationOrderCustomerFileStatuses;
  customerFile: IOwnFile;
};

export type AssociationOrderCustomerFileStatuses = '1' | '2' | '3' | '4' | '5';
export type CustomerFileAppearanceStatuses = '1' | '2' | '3';
export type CustomerFileVerificationStatuses = '1' | '2' | '3' | '4';

export type TOrderAExternalCredentials = {
  dateAdded: string;
  dateRemoved?: string;
  dateRestored?: string;
  idStatus: AssociationOrderCustomerExternalCredentialStatuses;
  ExternalCredential: TCustomerExternalCredential;
};

export enum AssociationOrderCustomerExternalCredentialStatuses {
  CLAIMED = 1,
  SET,
  CONFIRMED,
  REMOVED,
  WITHDRAWN,
}

export type IOrderMainWriterOrder = {
  writer: ICommonUser;
  associationDraftWriterFiles?: IAssociationDraftWriterFile[];
  associationWriterFiles?: IAssociationWriterFile[];
};

export type IAssociationWriterFile = {
  dateAdded: string;
  idStatus: AssociationWriterOrderWriterFileStatuses;
  writerFile: IWriterFile;
};
export type IAssociationDraftWriterFile = {
  dateAdded: string;
  idStatus: AssociationWriterOrderDraftWriterFileStatuses;
  writerFile: IWriterFile;
};

export type AssociationWriterOrderWriterFileStatuses = '1' | '2' | '3' | '4' | '5' | '6';
export type AssociationWriterOrderDraftWriterFileStatuses = '1' | '2' | '3' | '4' | '5' | '6';
