// Docs: https://ant.design/@/notification/
import { message } from 'antd';

message.config({ top: 5, duration: 3, maxCount: 1 });

// Create link for unique name (different from antd message)
export const msg = message;

export const showSuccessCreatedMsg = () => msg.success('Successfully created.');
export const showSuccessUpdatedMsg = () => msg.success('Successfully updated.');
