import { IS_DEV } from '@/config/constants';
import { emptyApi } from '@/store/emptyApi';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import global from '@/store/global/globalSlice';
import auth from '@/store/auth/authSlice';
import order from '@/store/order/orderSlice';
import localPreferences from '@/store/localPreferences/localPreferenceSlice';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync';

export const rootReducer = combineReducers({
  global,
  auth,
  localPreferences,
  order,
  [emptyApi.reducerPath]: emptyApi.reducer,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['global', 'auth', 'localPreferences', 'order'],
};

const setupStore = () =>
  configureStore({
    reducer: persistReducer(persistConfig, rootReducer),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
        thunk: true,
        devTools: IS_DEV,
      }).concat([
        emptyApi.middleware,
        createStateSyncMiddleware({
          whitelist: ['auth/setTokens', 'auth/logout', 'auth/setUser'],
        }),
      ]),
  });

export const store = setupStore();
export const persistor = persistStore(store);

initMessageListener(store);

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
export type AppGetState = () => RootState;
