import { emptyApi as api } from '@/store/emptyApi';
import { EntityState } from '@reduxjs/toolkit';
import { handleMessagePatch, handleMessagesRead, handleOutgoingOwnMessage, transformMessagesToDict } from '../lib/messengerService';
import {
  CommonMessage,
  CommonMessageContextCreateRequest,
  CommonTicketMessagesReadRequest,
  CommonTicketMessagesReadResponse,
  CommonTicketMessageUpdateRequest,
  CommonTicketPatchMessage,
  CommonTicketWithUnreadMessagesResponse,
} from '../types/messages';

const path = '/ticket-messages';

/**
 * Messenger common endpoints
 * keepUnusedDataFor: 0.1 -> defaults to 60
 * This is how long RTK Query will keep your data cached for after the last component unsubscribes.
 * https://redux-toolkit.js.org/rtk-query/api/createApi#keepunuseddatafor
 */
export const messengerApi = api.injectEndpoints({
  endpoints: ({ mutation, query }) => ({
    // Ticket [common endpoints]
    // -- get messages
    getTicketMessages: query<EntityState<CommonMessage>, number>({
      query: (id) => ({ url: `/tickets/${id}/messages` }),
      transformResponse: transformMessagesToDict, // [] -> {ids: [], entities: [{id: , ...}]}
      extraOptions: { customErrorHandler: true },
      providesTags: ['TicketMessage', { type: 'TicketMessage', id: 'MESSENGER' }],
      keepUnusedDataFor: 0.1,
    }),
    // -- send message
    addTicketMessage: mutation<CommonMessage, CommonMessageContextCreateRequest>({
      query: (body) => ({ url: path, method: 'POST', body }),
      onQueryStarted: handleOutgoingOwnMessage,
      extraOptions: { customErrorHandler: true },
    }),
    // -- edit own messages
    updateTicketMessage: mutation<CommonMessage, CommonTicketMessageUpdateRequest>({
      query: ({ id, body }) => ({ url: `${path}/${id}`, method: 'PUT', body }),
      onQueryStarted: handleMessagePatch,
      extraOptions: { customErrorHandler: true },
    }),
    // -- retract own messages
    retractTicketMessage: mutation<CommonMessage, CommonTicketPatchMessage>({
      query: ({ id }) => ({ url: `${path}/${id}/retract`, method: 'DELETE' }),
      onQueryStarted: handleMessagePatch,
      extraOptions: { customErrorHandler: true },
    }),
    // -- tickets with unread messages
    getTicketMessagesUnread: query<CommonTicketWithUnreadMessagesResponse, void>({
      query: () => ({ url: `${path}/unread` }),
      extraOptions: { customErrorHandler: true },
      keepUnusedDataFor: 0.1,
      providesTags: ['TicketMessage', { type: 'TicketMessage', id: 'INBOX' }],
    }),
    // -- unread messages counter
    getTicketMessagesUnreadCount: query<number, void>({
      query: () => ({ url: `${path}/unread/count` }),
      transformResponse: (res: { unreadMessages?: number }) => res?.unreadMessages ?? 0,
      providesTags: ['TicketMessage', { type: 'TicketMessage', id: 'COUNTER' }],
    }),
    // -- mark message as read
    readTicketMessage: mutation<CommonTicketMessagesReadResponse, CommonTicketMessagesReadRequest>({
      query: ({ ids }) => ({ url: `${path}/read`, method: 'PATCH', body: ids }),
      extraOptions: { customErrorHandler: true },
      onQueryStarted: handleMessagesRead,
      invalidatesTags: [{ type: 'TicketMessage', id: 'COUNTER' }],
    }),
  }),
  overrideExisting: false,
});

// Exports
export const {
  // Tickets
  useAddTicketMessageMutation,
  useGetTicketMessagesQuery,
  useUpdateTicketMessageMutation,
  useRetractTicketMessageMutation,
  useGetTicketMessagesUnreadQuery,
  useGetTicketMessagesUnreadCountQuery,
  useReadTicketMessageMutation,
} = messengerApi;
