import { Spin } from 'antd';
import s from './SpinFullscreen.module.less';

type Props = {
  tip?: string
}

export const SpinFullscreen = ({ tip }: Props) =>
  <div className={s.SpinFullscreen}>
    <Spin
      size="large"
      tip={tip}
      delay={100}
    />
  </div>;