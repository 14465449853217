import { useAppContext } from '@/app/contexts/AppContext';

export const useBreakpoints = () => {
  const { breakpoint } = useAppContext();

  return {
    isXs: breakpoint === 'xs',
    isXsMin: !['xs'].includes(breakpoint),
    isSm: ['xs', 'sm'].includes(breakpoint),
    isSmMin: !['xs', 'sm'].includes(breakpoint),
    isMd: ['xs', 'sm', 'md'].includes(breakpoint),
    isMdMin: !['xs', 'sm', 'md'].includes(breakpoint),
    isLg: ['xs', 'sm', 'md', 'lg'].includes(breakpoint),
    isLgMin: !['xs', 'sm', 'md', 'lg'].includes(breakpoint),
    isXl: ['xs', 'sm', 'md', 'lg', 'xl'].includes(breakpoint),
    isXlMin: !['xs', 'sm', 'md', 'lg', 'xl'].includes(breakpoint),
    isXxl: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'].includes(breakpoint),
    isXxlMin: !['xs', 'sm', 'md', 'lg', 'xl', 'xxl'].includes(breakpoint),
    isXxxl: breakpoint === 'xxxl',
  };
};
