import { useAuthLoginMutation, useAuthLogoutMutation, useAuthSignUpMutation } from '@/api/auth';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { authLogout } from '@/store/auth/authActions';
import { selectAuth, setTokens } from '@/store/auth/authSlice';
import { RootState } from '@/store/store';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { emptyApi } from '@/store/emptyApi';

export const useAuth = (): RootState['auth'] => useAppSelector(selectAuth);

export const useQueryLogout = () => {
  const [requestLogout] = useAuthLogoutMutation();
  const navigate = useNavigate();
  const { isLoggedIn } = useAuth();

  const [searchParams, setSearchParams] = useSearchParams();
  const logoutParam = searchParams.get('logout');

  useEffect(() => {
    if (logoutParam !== null && isLoggedIn) {
      searchParams.delete('logout');
      setSearchParams(searchParams);
      requestLogout()
        .then(() => navigate('/login'))
        .catch();
    }
  }, [isLoggedIn, logoutParam, navigate, requestLogout, searchParams, setSearchParams]);
};
export const useMagicLogin = () => {
  const dispatch = useAppDispatch();
  const [magicLogin] = useAuthLoginMutation();
  const [searchParams, setSearchParams] = useSearchParams();
  const magicLinkParam = searchParams.get('magicLink');

  magicLinkParam && console.log('useMagicLogin', magicLinkParam);

  useEffect(() => {
    if (magicLinkParam !== null) {
      magicLogin({ magicUuid: magicLinkParam })
        .unwrap()
        .then((tokens) => {
          dispatch(authLogout(emptyApi));
          setTimeout(() => {
            dispatch(setTokens(tokens));
          }, 0);
        })
        .catch();
      searchParams.delete('magicLink');
      setSearchParams(searchParams);
    }
  }, [dispatch, magicLinkParam, magicLogin, searchParams, setSearchParams]);
};

// detect login in another tab
export const useTrackUserId = () => {
  const { user } = useAuth();
  const [prevUserId, setUserId] = useState<number>(user.id);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (prevUserId && user.id && prevUserId !== user.id) {
      dispatch(emptyApi.util.resetApiState());
    }

    if (user.id) {
      setUserId(user.id);
    }
  }, [dispatch, prevUserId, user.id]);
};

export const useAutoSignUp = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const email = searchParams.get('signUpEmail');
  const [signUp] = useAuthSignUpMutation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user } = useAuth();

  const userEmail = user.primaryEmail?.address;

  useEffect(() => {
    if (email === null) return;
    signUp({ email })
      .unwrap()
      .then((tokens) => {
        dispatch(authLogout(emptyApi));
        searchParams.delete('signUpEmail');
        setSearchParams(searchParams);
        setTimeout(() => {
          dispatch(setTokens(tokens));
        }, 0);
      })
      .catch((err) => {
        searchParams.delete('signUpEmail');
        searchParams.append('email', email);
        setSearchParams(searchParams);
        if (err?.data?.error === 'Customer with that email already exists' && userEmail !== email) {
          dispatch(authLogout(emptyApi));
          navigate(`/login?${searchParams.toString()}`);
          return;
        }
      });
  }, [dispatch, email, searchParams, setSearchParams, signUp, navigate, userEmail]);
};
