import { useCallback, useEffect, useState } from 'react';
import { TWindowBreakpoint } from '@/types/common/components';
import { getCSSVar } from '@/helpers/utils';
import { throttle } from 'lodash';

export const useTrackBreakpoint = () => {
  const [breakpoint, setBreakPoint] = useState<TWindowBreakpoint>('xxl');

  const watchBreakPoint = useCallback(() => {
    const breakPoints: [TWindowBreakpoint, string][] = [
      ['xs', getCSSVar('--xs')],
      ['sm', getCSSVar('--sm')],
      ['md', getCSSVar('--md')],
      ['lg', getCSSVar('--lg')],
      ['xl', getCSSVar('--xl')],
      ['xxl', getCSSVar('--xxl')],
    ];

    const targetBreakPoint = breakPoints.find(([_, size]) => {
      return window.matchMedia(`only screen and (max-width: ${size}px)`).matches;
    });

    setBreakPoint(targetBreakPoint?.[0] || 'xxxl');
  }, []);

  useEffect(() => {
    watchBreakPoint();

    const debouncedWatchBreakPoint = throttle(watchBreakPoint, 100);
    window.addEventListener('resize', debouncedWatchBreakPoint);

    return () => {
      window.removeEventListener('resize', debouncedWatchBreakPoint);
    };
  }, [watchBreakPoint]);

  return breakpoint;
};
