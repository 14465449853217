import { useAppContext } from '@/app/contexts/AppContext';
import { X_HOST } from '@/config/constants'; // get current domain (dynamically)
import { _g4log } from '@/helpers/gtag'; // https://www.npmjs.com/package/react-ga4
import { FC, useEffect } from 'react';
import GA4 from 'react-ga4';
import { InitOptions } from 'react-ga4/types/ga4';
import { useLocation } from 'react-router-dom';

// https://www.npmjs.com/package/react-ga4
// https://github.com/react-ga/react-ga

export const GoogleAnalytics4: FC = () => {
  const { config } = useAppContext();
  const trackingId = config.site.trackingId;
  const adsId = config.site.adsId;

  const isReady = trackingId && GA4.isInitialized;

  // Initialize
  useEffect(() => {
    if (!trackingId) return;

    /* eslint-disable camelcase */
    let products: InitOptions[] = [
      {
        trackingId,
        gtagOptions: {
          currency: 'USD',
          linker: { domains: [X_HOST, `app.${X_HOST}`, `ga.${X_HOST}`] }, // cross-domain app
          // cookie_domain: cookieDomain,
          send_page_view: false,
          anonymize_ip: true,
          debug_mode: true,
        },
        gaOptions: { debug_mode: true },
      },
    ];

    if (adsId) {
      products.push({
        trackingId: adsId,
        gaOptions: { debug_mode: true },
        gtagOptions: { debug_mode: true },
      });
    }
    /* eslint-enable camelcase */

    setTimeout(() => {
      GA4.initialize(products);
      _g4log('Initialized!', { products, page: document.title });
      (globalThis || window)._ga = GA4;
    }, 500);

    // save search params to cookies
    try {
      window.location.search
        .replace('?', '')
        .split('&')
        .map((i) => (document.cookie = `${i};`));
    } catch (e) {
      console.error('Set cookie from search params failed.');
    }
  }, [trackingId, adsId]);

  const { pathname } = useLocation();

  // [global tracker] "Pageview" (event) -> Disable default history change
  useEffect(() => {
    if (!isReady) return; // not initialized yet
    if (pathname === '/') return; // prevent duplicates of 1st pageview

    _g4log('PageView', pathname);

    GA4.send({ hitType: 'pageview', page: pathname, title: document.title });
  }, [isReady, pathname]);

  return null;
};
