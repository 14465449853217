import cn from 'classnames';
import { ReactNode } from 'react';
import s from './Container.module.less';

type Props = {
  children: ReactNode;
  className?: string;
  left?: boolean;
};

export const Container = ({ children, className, left }: Props) => {
  return <div className={cn(s.wrapper, className, { [s.left]: left })}>{children}</div>;
};
