import {
  WebsocketCreatePayload,
  WSCloseCodes,
  WSConnectionClosedEventDetail,
  WSConnectionErrorEventDetail,
  WSConnectionOpenEventDetail,
  WSMessage,
  WSMessageEventDetail,
  WSReadyState,
  WSStatusChangeEventDetail,
} from '@/shared/features/websockets/types/websockets';
import { wsLog } from '@/shared/features/websockets/helpers/log';
import { WSG_BASE_URL } from '@/shared/features/websockets/config/config';
import { msg } from '@/shared/helpers/msg';
import { AppWebsocketPingator } from '@/shared/features/websockets/classes/AppWebsocketPingator';

export class AppWebsocketConnection {
  protected connection: WebSocket | null;
  protected path: string;
  protected pingator: AppWebsocketPingator | null;

  constructor(path: string) {
    this.path = path;
    this.pingator = null;
    this.connection = null;
  }

  public create({ token, notify }: WebsocketCreatePayload) {
    wsLog('👋 connecting to wsg', this.path);
    this.connection = new WebSocket(`${WSG_BASE_URL}/${this.path}?accessToken=${token}`);

    this.pingator = new AppWebsocketPingator(this.connection, this.dispatchStatusChangeEvent);

    this.dispatchStatusChangeEvent();

    this.connection.onerror = (e) => {
      wsLog(`Error connection ${this.path}`);

      console.log('e', e);

      this.dispatchStatusChangeEvent();

      this.dispatchConnectionErrorEvent(e);
    };

    this.connection.onopen = (event) => {
      wsLog('🟢 opened');

      if (!this.pingator) throw new Error('Invalid invocation');
      this.pingator.reset();

      this.dispatchStatusChangeEvent();

      this.dispatchConnectionOpenEvent(event);

      return notify && msg.info('Connected'); // show notification
    };

    this.connection.onmessage = (e) => {
      const { event } = JSON.parse(e.data) as WSMessage<unknown>;
      //
      // Received Pong
      if (event.name === 'Pong') {
        // Pong received -> healthCheck -> success
        if (!this.pingator) throw new Error('Invalid invocation!');
        this.pingator.stopHealthCheck();
        return;
      }

      this.dispatchMessageEvent(e);
    };

    this.connection.onclose = (e) => {
      if (!this.pingator) throw new Error('Invalid invocation.');
      this.dispatchStatusChangeEvent();

      wsLog(`code: ${e.code} reason: ${e.reason || 'silent'}`);

      // if (e.wasClean) {
      //   return wsLog(`⚫️ closed clean ${this.path}`);
      // }

      wsLog(`🔴 lose connection ${this.path}`);

      this.pingator.destroy();

      console.log('DISPATCHING CLOSED EVENT');
      this.dispatchConnectionClosedEvent(e);
    };
  }

  public close(code: WSCloseCodes, reason = '') {
    if (!this.connection) return;
    wsLog('closed', this.path, reason);
    this.connection.close(code);
    if (reason) return msg.error(reason);
  }

  protected dispatchStatusChangeEvent() {
    if (!this.connection) throw new Error('Invalid method invocation');
    const socketStatusChanged = new CustomEvent<WSStatusChangeEventDetail>('wsConnectionChange', {
      bubbles: true,
      detail: {
        path: this.path,
        status: this.connection.readyState || WSReadyState.Closed,
      },
    });

    document.dispatchEvent(socketStatusChanged);
  }

  protected dispatchMessageEvent(e: MessageEvent) {
    if (!this.connection) throw new Error('Invalid method invocation');
    const messageReceived = new CustomEvent<WSMessageEventDetail>('wsMessageReceived', {
      bubbles: true,
      detail: {
        path: this.path,
        data: e,
      },
    });

    document.dispatchEvent(messageReceived);
  }

  protected dispatchConnectionErrorEvent(e: Event) {
    const connectionErrorEvent = new CustomEvent<WSConnectionErrorEventDetail>('wsConnectionError', {
      bubbles: true,
      detail: {
        path: this.path,
        data: e,
      },
    });

    document.dispatchEvent(connectionErrorEvent);
  }

  protected dispatchConnectionClosedEvent(e: CloseEvent) {
    const connectionCloseEvent = new CustomEvent<WSConnectionClosedEventDetail>('wsConnectionClose', {
      bubbles: true,
      detail: {
        path: this.path,
        data: e,
      },
    });

    document.dispatchEvent(connectionCloseEvent);
  }

  protected dispatchConnectionOpenEvent(e: Event) {
    const connectionOpenEvent = new CustomEvent<WSConnectionOpenEventDetail>('wsConnectionOpen', {
      bubbles: true,
      detail: {
        path: this.path,
        data: e,
      },
    });

    document.dispatchEvent(connectionOpenEvent);
  }
  get readyState() {
    return this.connection?.readyState;
  }
}
