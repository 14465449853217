import { Button } from '@/components/common/button/Button';
import { useNavigate } from 'react-router-dom';
import { IconSvg } from '@/components/common/icons/IconSvg';
import s from './ProfilePasswordNotificationPopoverContent.module.less';

type ProfilePasswordNotificationPopoverContentProps = {
  onClick: () => void;
};

export const ProfilePasswordNotificationPopoverContent = ({ onClick }: ProfilePasswordNotificationPopoverContentProps) => {
  const navigate = useNavigate();
  return (
    <div className={s.wrapper}>
      <IconSvg icon="IcoSUserLock" className={s.lock} />
      <p className={s.text}>Don’t forget to set your password here</p>
      <Button
        size={'large'}
        icon={'IcoRGear'}
        modification={'primary'}
        onClick={() => {
          navigate('/profile#password');
          onClick();
        }}
      >
        Set password
      </Button>
    </div>
  );
};
