// @ts-nocheck

import { notification } from '@/shared/features/notification/notification';

/**
 * Chatwoot
 * Docs: https://www.chatwoot.com/docs/product/channels/live-chat/sdk/setup
 */

export const getChatActions = () => {
  const showChat = () => window['$chatwoot']?.toggle?.('show');

  return {
    showChat,
  };
};

export const loadChatScript = (websiteToken: string) => {
  if (!websiteToken) return;
  window.chatwootSettings = { position: 'right', type: 'standard', launcherTitle: 'Chat with us' };

  (function (d, t) {
    let BASE_URL = 'https://chat.cloudsend.pro';
    let g = d.createElement(t),
      s = d.getElementsByTagName(t)[0];
    g.src = BASE_URL + '/packs/js/sdk.js';
    g.defer = true;
    // @ts-ignore
    g.async = true;
    s.parentNode.insertBefore(g, s);
    g.onload = function () {
      // wait 100ms for chatwootSDK to be loaded & executed
      setTimeout(() => {
        if (!window.chatwootSDK) {
          notification.error({
            message: 'LiveChat is not loaded',
            description: 'Please check your blockers & reload the page',
          });
          console.log('Chatwoot is not loaded');
          return;
        }
        window.chatwootSDK?.run({
          websiteToken,
          baseUrl: BASE_URL,
        });
      }, 100);
    };
  })(document, 'script');
};
