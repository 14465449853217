import Hotjar from '@hotjar/browser';
import { useEffect } from 'react';

interface Props {}

// TODO: Test it...
export const HotjarWidget: FC<Props> = () => {
  useEffect(() => {
    if (!window.location.host.match(/gradesmagic/gi)) return;
    const siteId = 3583304;
    const hotjarVersion = 6;
    Hotjar.init(siteId, hotjarVersion);
    console.log('[Hotjar] Initialized');
  }, []);

  return null;
};
