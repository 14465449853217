import { Button } from '@/components/common/button/Button';
import { Flex } from '@/components/common/layout/Flex';
import { Navigation } from '@/components/common/layout/Navigation';
import { trackEvent } from '@/helpers/gtag';
import { useBreakpoints } from '@/hooks/breakpoints/useBreakpoints';
import { useOrderformFlags } from '@/hooks/helpers';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { selectMenuCollapsed, setCollapsedMenu } from '@/store/global/globalSlice';
import cn from 'classnames';

import s from './Sidebar.module.less';

type Props = {
  className?: string;
};

export const Sidebar = ({ className }: Props) => {
  const dispatch = useAppDispatch();
  const { isOrderFormPage } = useOrderformFlags();

  const prevState = useAppSelector(selectMenuCollapsed);
  const collapsed = prevState || isOrderFormPage;
  const handleCollapsed = (bool) => {
    dispatch(setCollapsedMenu(bool));
    // [tracker] "SidebarView"
    trackEvent({ category: 'Dashboard', action: `Sidebar ${bool ? 'expand' : 'collapse'}` });
  };

  const { isMd } = useBreakpoints();

  if (isMd) return null;

  return (
    <div
      className={cn(s.wrapper, className, {
        [s.collapsed]: collapsed,
      })}
    >
      <div className={s.menus}>
        <Navigation collapsed={collapsed} />
      </div>
      {!isOrderFormPage && (
        <Flex justifyContent={'center'} className={s.collapseButton}>
          <Button icon={collapsed ? 'IcoRChevronRight' : 'IcoRChevronLeft'} onClick={() => handleCollapsed(!collapsed)} circle />
        </Flex>
      )}
    </div>
  );
};
