import { APP_VERSION, IS_DEV, SENTRY_DSN } from '@/config/constants';
import * as Sentry from '@sentry/react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { useEffect } from 'react';

export const initSentry = () => {
  if (IS_DEV) return;

  // https://docs.sentry.io/platforms/javascript/guides/react/
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        createRoutesFromChildren,
        matchRoutes,
        useLocation,
        useNavigationType,
        useEffect,
      }),

      // https://docs.sentry.io/platforms/javascript/guides/react/session-replay/
      Sentry.replayIntegration(),
    ],
    ignoreErrors: [/ResizeObserver/, /Non-Error promise rejection captured/, /Network Error/],

    environment: window.location.host.match(/stage/gi) ? 'stage' : 'production',

    /*
     * Set tracesSampleRate to 1.0 to capture 100%
     * of transactions for performance monitoring.
     * We recommend adjusting this value in production
     */
    release: APP_VERSION,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
  });
};
