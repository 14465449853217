import { IconSvg } from '@/components/common/icons/IconSvg';
import { BadgeCounter } from '@/shared/components/BadgeCounter';
import { useGetTicketMessagesUnreadCountQuery } from '@/shared/features/messenger/api/messenger';
import { useSmartPolling } from '@/shared/hooks/useSmartPolling';
import { Link } from 'react-router-dom';

interface Props {
  showCount?: boolean;
}

export const UnreadTicketMessagesCounter: FC<Props> = ({ showCount = true }) => {
  const { pollingInterval } = useSmartPolling(30);
  const { data: unreadCount } = useGetTicketMessagesUnreadCountQuery(undefined, {
    skip: !showCount,
    pollingInterval, // refresh every 30 seconds only when browser tab is active
  });
  return (
    <Link to="/inbox" style={{ marginTop: 3, zIndex: 1, color: 'inherit', display: 'flex', alignItems: 'center' }}>
      <BadgeCounter count={unreadCount} type="alert" overflowCount={999}>
        <IconSvg icon="IcoREnvelopes" size={28} />
      </BadgeCounter>
    </Link>
  );
};
