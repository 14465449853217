/*
 * Docs: https://ant.design/components/notification
 * Example of use:
 *    notify.info ({message:'', description:''})
 */
import { notification as antNotification } from 'antd';

// Override defaults
antNotification.config({
  duration: 10, // custom duration
});

export const notification = antNotification; // export configured notification
