import { countries } from '@/components/common/contacts/countries';
import { IPhoneItem } from '@/types/phone';
import { Space, Tooltip } from 'antd';
import cn from 'classnames';
import { FC, useMemo } from 'react';

type Props = {
  phone: IPhoneItem | undefined;
};

const COUNTRY_NAME_INDEX = 0;
const FLAG_INDEX = 2;
const ID_CALLING_CODE_INDEX = 3;
const MASKED_NUMBER_INDEX = 4;

export const PhoneNumber: FC<Props> = ({ phone = { number: '', idCallingCode: 0 } }) => {
  const { idCallingCode, number } = phone;

  const numberParams = useMemo(() => {
    return [...countries].reverse().find((el) => el[ID_CALLING_CODE_INDEX] === idCallingCode);
  }, [idCallingCode]);

  const flag = numberParams?.[FLAG_INDEX];

  const countryName = numberParams?.[COUNTRY_NAME_INDEX];

  const formattedNumber = useMemo(() => {
    if (!numberParams) return `+${idCallingCode} ${number}`;

    let maskedNumber = numberParams[MASKED_NUMBER_INDEX];

    if (typeof maskedNumber !== 'string') {
      maskedNumber = '... ... ... ... ..';
    }

    for (let i = 0; i < number.length; i++) {
      maskedNumber = maskedNumber.replace('.', number[i]);
    }

    maskedNumber = maskedNumber.replace(/\./g, '');

    return `+${idCallingCode} ${maskedNumber}`;
  }, [numberParams, number, idCallingCode]);

  if (!number && !idCallingCode) return null;

  return (
    <Space>
      {flag && (
        <Tooltip title={countryName}>
          <div className="react-tel-input">
            <div className={cn('flag', flag)}></div>
          </div>
        </Tooltip>
      )}
      <div style={{ lineHeight: 0.9 }}>{formattedNumber}</div>
    </Space>
  );
};
