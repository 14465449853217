import { useForm } from 'antd/lib/form/Form';
import { Col, Form, Row } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker/generatePicker';
import { omit } from 'lodash';
import moment, { Moment } from 'moment';
import { Dispatch, ReactNode, SetStateAction, useCallback, useEffect } from 'react';

import type { LabelsResolver } from '../../helpers';
import type { GeneralSearchParams, SearchParams } from '../../types';

import { SearchDateRange } from './SearchDateRange';

type FormPayload = { [key in TimeIntervalField]: [Moment | null, Moment | null] | null };

export const timeIntervalFieldNames = [
  'dateAdded',
  'dateUpdated',
  'dateActive',
  'dateExpires',
  'dateExecuted',
  'datePending',
  'dateDue',
] as const;
export type TimeIntervalField = typeof timeIntervalFieldNames[number];

export type SearchDateFiltersFormProps<T extends GeneralSearchParams> = {
  isPopover?: boolean;
  params: SearchParams<T>;
  keys: TimeIntervalField[];
  onChange: Dispatch<SetStateAction<SearchParams<T>>>;
  keyLabel?: string;
  formatValues?: boolean;
  picker?: RangePickerProps<moment.Moment>;
  closeDatePickerIcon: ReactNode;
  dateRangeIcon: ReactNode;
  t: LabelsResolver;
  showTime?: boolean;
  timezone?: string;
};

const formatFn = (value: string | Moment, timezone?: string) => {
  const momentValue: any = moment(value);

  return timezone && 'tz' in momentValue ? momentValue.tz(timezone).utc().format() : momentValue.format();
};

export const SearchDateFiltersForm = <T extends GeneralSearchParams>({
  params,
  onChange,
  keys,
  keyLabel,
  formatValues = true,
  picker,
  isPopover,
  t,
  closeDatePickerIcon,
  dateRangeIcon,
  timezone,
  showTime,
}: SearchDateFiltersFormProps<T>) => {
  const [form] = useForm();

  const isDateParam = useCallback((param) => (keys as string[]).includes(param.replace(/(From|To)$/, '')), [keys]);

  const handleSubmit = (payload: FormPayload) => {
    const [fieldName, fieldValue] = Object.entries(payload)[0];
    const fromKey = `${fieldName}From`;
    const toKey = `${fieldName}To`;
    const fromValue = fieldValue?.[0] ? (formatValues ? formatFn(fieldValue[0], timezone) : fieldValue[0]) : null;
    const toValue = fieldValue?.[1] ? (formatValues ? formatFn(fieldValue[1], timezone) : fieldValue[1]) : null;

    // handle clear
    if (fieldValue) {
      const newParams = { ...params };

      if (fromValue) {
        newParams[fromKey] = fromValue;
      } else {
        delete newParams[fromKey];
      }

      if (toValue) {
        newParams[toKey] = toValue;
      } else {
        delete newParams[toKey];
      }
      onChange(newParams);
    } else {
      onChange(omit(params, [fromKey, toKey]) as SearchParams<T>);
    }
  };

  useEffect(() => {
    if (!form) return;
    const targetParams = Object.entries(params).filter(([key]) => isDateParam(key));
    const dateParams: FormPayload | Record<string, any> = {};

    targetParams.forEach(([key, value]) => {
      const keyValue = key.replace(/(From|To)$/, '');
      // @ts-ignore
      const momentValue = value ? (timezone && 'tz' in moment ? moment(value).utc().tz(timezone) : moment(value).local()) : null;
      const noKeyValue = !dateParams[keyValue];
      if (key.endsWith('From')) {
        if (noKeyValue) {
          dateParams[keyValue] = [momentValue, null];
        } else {
          dateParams[keyValue][0] = momentValue;
        }
      } else if (noKeyValue) {
        dateParams[keyValue] = [null, momentValue];
      } else {
        dateParams[keyValue][1] = momentValue;
      }
    });

    keys.forEach((key) => {
      if (!dateParams[key]) dateParams[key] = null;
    });

    form.setFieldsValue(dateParams);
  }, [params, form, isDateParam, keys, timezone]);

  return (
    <Form onValuesChange={handleSubmit} form={form}>
      <Row gutter={[16, 0]} style={{ maxWidth: 500, minWidth: 250 }}>
        {keys.map((key) => (
          <Col key={key} span={isPopover ? (keys.length === 1 ? 24 : 12) : 24}>
            <p>{keyLabel || t.resolve('dateParams', { name: key })}:</p>
            <Form.Item name={key}>
              <SearchDateRange
                picker={picker}
                closeDatePickerIcon={closeDatePickerIcon}
                dateRangeIcon={dateRangeIcon}
                t={t}
                showTime={showTime}
              />
            </Form.Item>
          </Col>
        ))}
      </Row>
    </Form>
  );
};
