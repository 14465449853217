// Sync this type with _variables.app.less
// TODO: Sync it with new cssVariables
import { lowerCase, upperFirst } from 'lodash';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { AVAILABLE_ERROR_CODES, AvailableErrorCode } from '@/components/common/error/ErrorPage';

export type AppColors =
  | 'brand'
  | 'text'
  | 'success'
  | 'error'
  | 'warning'
  | 'muted'
  | 'info'
  | 'danger'
  | 'support'
  | 'writer'
  | 'customer'
  | 'text-alt';

export const getCSSVar = (name: string) => getComputedStyle(document.documentElement).getPropertyValue(name);

export const getColor = (color: AppColors) => {
  return getCSSVar(`--${color}-color`);
};

export const isTouchDevice = () => !window.matchMedia('(hover: hover) and (pointer: fine)').matches;

export const stripTags = (str?: string) => str?.replace(/(<([^>]+)>)/gi, '');

export interface CustomScrollAction {
  el: Element;
  top: number;
  left: number;
}

export const customScrollActions = (actions: CustomScrollAction[], extraOffset: number) => {
  const headerHeight = getCSSVar('--header-height');
  const offset = parseInt(headerHeight, 10) + extraOffset;
  return actions.forEach(({ el, top }) => (el.scrollTop = top - offset));
};

export const hexToRgb = (hex) => {
  let rgb = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex.trim());
  return (
    rgb
      ? [
          parseInt(rgb[1], 16), // r
          parseInt(rgb[2], 16), // g
          parseInt(rgb[3], 16), // b
        ]
      : [0, 0, 0, 1]
  ).join(',');
};

export const normalCase = (str: string) => upperFirst(lowerCase(str));
export const replaceTemplate = (str: string, s: string) => (str.match('%s') ? str.replace('%s', s) : str);

export const getFirstFromList = (list): number | undefined => (list?.length ? list[0]?.id : undefined);

export const trimObjValues = (obj) =>
  Object.keys(obj).reduce((acc, curr) => {
    acc[curr] = typeof obj[curr] === 'string' ? obj[curr].trim() : obj[curr];
    return acc;
  }, {});

// Get error code from FetchBaseQueryError
export const getErrorCode = (error: FetchBaseQueryError | SerializedError | undefined): AvailableErrorCode => {
  if (!error) return 0; // unknown
  if ('originalStatus' in error) return AVAILABLE_ERROR_CODES.includes(error.originalStatus as any) ? (error.originalStatus as any) : 0; // main
  if ('status' in error) return AVAILABLE_ERROR_CODES.includes(error.status as any) ? (error.status as any) : 0; // fallback
  return 0; // default
};

export const debouncePromise = (inner: (...args: any) => any, ms = 0): ((...args: any) => Promise<any>) => {
  let timer: ReturnType<typeof setTimeout> | null = null;
  let resolves: any[] = [];

  return (...args: any[]) => {
    // Run the function after a certain amount of time
    timer && clearTimeout(timer);
    timer = setTimeout(() => {
      // Get the result of the inner function, then apply it to the resolve function of
      // each promise that has been created since the last time the inner function was run
      const result = inner(...args);
      resolves.forEach((r) => (typeof r === 'function' ? r(result) : null));
      resolves = [];
    }, ms);

    return new Promise((r) => resolves.push(r));
  };
};

export const getErrorResponseText = (err: any): string | undefined =>
  err && typeof err === 'object' && 'error' in err && err.error?.data?.error;
