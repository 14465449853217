import type { ReactNode } from 'react';
import { Dropdown, Menu, Form, Button } from 'antd';
import type { SearchEngineLayout } from '../types';
import type { LabelsResolver } from '../helpers';

type SearchEngineResetFiltersMenuProps = {
  defaultParams: Record<string, any>;
  protectedFields?: (string | number | symbol)[];
  setParams: (args: any) => void;
  view: SearchEngineLayout;
  t: LabelsResolver;
  resetFilterMenuIcon: ReactNode;
  resetToDefaultsIcon: ReactNode;
  clearFiltersIcon: ReactNode;
};

export const SearchEngineResetFiltersMenu = ({
  defaultParams,
  protectedFields,
  setParams,
  view,
  resetFilterMenuIcon,
  resetToDefaultsIcon,
  clearFiltersIcon,
  t,
}: SearchEngineResetFiltersMenuProps): JSX.Element => {
  const form = Form.useFormInstance();

  const handleMenuClick = ({ key }: { key: string }) => {
    const nullifiedEntries = Object.fromEntries(
      Object.entries(form.getFieldsValue()).map(([key, value]) => [key, protectedFields?.includes(key) ? value : undefined]),
    );

    if (key === 'defaults') {
      const updatedValues = { ...nullifiedEntries, ...defaultParams };
      setParams(updatedValues);
      form.setFieldsValue(updatedValues);
    } else if (key === 'clear') {
      const updatedValues = { size: 10, ...nullifiedEntries, page: 1 };
      setParams(updatedValues);
      form.setFieldsValue(updatedValues);
    }
    form.submit();
  };

  const items = [
    {
      label: (
        <span className="core-search-engine-mobile-menu__item">
          {resetToDefaultsIcon} <span className="core-search-engine-mobile-menu__label">{t.resolve('resetToDefaults')}</span>
        </span>
      ),
      key: 'defaults',
    },
    {
      label: (
        <span className="core-search-engine-mobile-menu__item">
          {clearFiltersIcon} <span className="core-search-engine-mobile-menu__label">{t.resolve('clearAllFilters')}</span>
        </span>
      ),
      key: 'clear',
    },
  ];

  return view === 'mobile' ? (
    <Menu onClick={handleMenuClick} className="core-search-engine-mobile-menu" items={items} />
  ) : (
    <Dropdown arrow placement="bottomRight" trigger={['click']} overlay={<Menu onClick={handleMenuClick} items={items} />}>
      <Button className="ant-btn-square">{resetFilterMenuIcon}</Button>
    </Dropdown>
  );
};
