import { useUploadOwnFileMutation } from '@/api/ownFiles';
import { UPLOAD_ACCEPT_FILES } from '@/config/constants';
import { IOwnFile, IOwnFileTypes } from '@/types/file/ownFile';
import { Upload } from 'antd';

interface Props {
  onComplete?: (file: IOwnFile) => void;
  accept?: string;
  openOnClick?: boolean;
}

// Simple file uploader -> returns uploaded file details (IOwnFile)
export const UploadOwnFile: FC<Props> = ({ onComplete, accept, openOnClick, children }) => {
  const [uploadFile] = useUploadOwnFileMutation();

  return (
    <Upload
      openFileDialogOnClick={openOnClick}
      multiple={false}
      accept={accept || UPLOAD_ACCEPT_FILES}
      fileList={[]}
      onChange={async (uploadParam) => {
        const file = uploadParam.file.originFileObj;
        if (!file || !file.size) return console.log('[upload err] Invalid file size');

        // Prepare formData
        const body = new FormData();
        body.append('file', file);
        body.append('idType', String(IOwnFileTypes.Supplementary));

        // Upload
        try {
          const uploadedFile = await uploadFile(body).unwrap();
          // Success
          onComplete?.(uploadedFile);
        } catch (e) {
          console.error('[upload err]', e);
        }
      }}
      children={children}
    />
  );
};
