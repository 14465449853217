import { FEATURE } from '@/config/features';
import { authLogout } from '@/store/auth/authActions';
import { setTokens } from '@/store/auth/authSlice';
import { emptyApi } from '@/store/emptyApi';
import {
  IAuthLoginRequest,
  IAuthSignUpPayload,
  IAuthSignUpRequest,
  IMagicLoginRequest,
  IMagicUuid,
  IPasswordRecoverRequest,
  IPasswordRecoverUpdateRequest,
  IReportUnauthorisedAccessRequest,
  ITokenPair,
} from '@/types/auth';
import { msg } from '@/helpers/msg';
import { getErrorResponseText } from '@/helpers/utils';
import { trackEvent } from '@/helpers/gtag';

const { path } = FEATURE.Auth;

const injectedRtkApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    authLogin: build.mutation<ITokenPair, IAuthLoginRequest>({
      query: (body) => {
        return {
          url: `${path}/login`,
          method: 'POST',
          body,
        };
      },
      async onCacheEntryAdded(_, { dispatch, cacheDataLoaded }) {
        try {
          const { data } = await cacheDataLoaded;
          dispatch(setTokens(data));
        } catch (err) {}
      },
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          trackEvent({ action: 'Login success', category: 'Auth' });
        } catch (err: any) {
          let message = 'Invalid credentials';
          const responseText = getErrorResponseText(err);
          if (responseText === 'Invalid account status') {
            message = 'Invalid account status. Contact support for assistance';
          }

          if (responseText === 'Account not found') {
            message = 'Account not found. Contact support for assistance';
          }

          trackEvent({ action: 'Login failed', category: 'Auth', label: message });

          msg.error(message);
        }
      },
    }),
    authSignUp: build.mutation<ITokenPair, IAuthSignUpPayload>({
      query: (credentials) => {
        const body: IAuthSignUpRequest = {
          ...credentials,
        };
        return {
          url: `${path}/sign-up`,
          method: 'POST',
          body,
        };
      },
      extraOptions: { customErrorHandler: true },
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          trackEvent({ action: 'Signup success', category: 'Auth' });
        } catch (e) {
          trackEvent({ action: 'Signup failed', category: 'Auth' });
        }
      },
    }),
    authLogout: build.mutation<unknown, void>({
      query: () => ({
        url: `${path}/logout`,
        method: 'POST',
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {}

        dispatch(authLogout(emptyApi));
        trackEvent({ action: 'Logout', category: 'Auth' });
      },
    }),
    getLegacyMagicLink: build.query<IMagicUuid, void>({
      query: () => ({ url: `${path}/magic-login/legacy/request` }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          trackEvent({ action: 'Legacy', category: 'Auth' });
        } catch (err) {}
      },
      extraOptions: { customErrorHandler: true },
    }),
    reportUnauthorisedAccessRequest: build.mutation<void, IReportUnauthorisedAccessRequest>({
      query: (body) => ({ url: `${path}/magic-link/report`, body, method: 'PUT' }),
      extraOptions: { customErrorHandler: true },
    }),
    requestAuthPasswordRecovery: build.mutation<void, IPasswordRecoverRequest>({
      query: (body) => ({ url: `${path}/magic-password-recovery/request`, body, method: 'POST' }),
      extraOptions: { customErrorHandler: true },
    }),
    requestAuthMagicLogin: build.mutation<void, IMagicLoginRequest>({
      query: (body) => ({ url: `${path}/magic-login/request`, body, method: 'POST' }),
      extraOptions: { customErrorHandler: true },
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          trackEvent({ action: 'Magic link', category: 'Auth' });
        } catch (err) {}
      },
    }),
    recoverAuthPassword: build.mutation<ITokenPair, IPasswordRecoverUpdateRequest>({
      query: (body) => ({ url: `${path}/magic-password-recovery`, body, method: 'POST' }),
      extraOptions: { customErrorHandler: true },
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          trackEvent({ action: 'Recover', category: 'Auth' });
        } catch (err) {}
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useAuthLoginMutation,
  useAuthLogoutMutation,
  useAuthSignUpMutation,
  useLazyGetLegacyMagicLinkQuery,
  useRequestAuthPasswordRecoveryMutation,
  useRecoverAuthPasswordMutation,
  useReportUnauthorisedAccessRequestMutation,
  useRequestAuthMagicLoginMutation,
} = injectedRtkApi;
