import { AnimatedBackground } from '@/components/common/AnimatedBackground';
import { Button } from '@/components/common/button/Button';
import { Container } from '@/components/common/layout/Container';
import { Flex } from '@/components/common/layout/Flex';
import { useBreakpoints } from '@/hooks/breakpoints/useBreakpoints';
import { IcoExclamationCircle, IcoRGear, IcoRLockKeyhole } from '@/media/assets/icons';
import cn from 'classnames';
import { FunctionComponent, ReactNode, SVGProps } from 'react';

import s from './ErrorPage.module.less';

type ErrorTypes = number;
type Content = {
  image?: ReactNode;
  heading: string | ReactNode;
  text: string | ReactNode;
  buttons: ('retry' | 'back')[];
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
};

export const AVAILABLE_ERROR_CODES = [404, 403, 500, 503, 0] as const;

export type AvailableErrorCode = typeof AVAILABLE_ERROR_CODES[number];

const pageContent: Record<AvailableErrorCode, Content> = {
  404: {
    heading: (
      <>
        Oops!
        <br />
        Sorry, we can’t find that page!
      </>
    ),
    text: <>Either something went wrong or that page does not exist anymore.</>,
    buttons: ['back', 'retry'],
    icon: IcoExclamationCircle,
  },
  403: {
    heading: <>You’re not permitted to see this.</>,
    text: (
      <>
        The page you’re trying to access has restricted access.
        <br />
        If you feel this is mistake, please contact our support team.
      </>
    ),
    buttons: ['back', 'retry'],
    icon: IcoRLockKeyhole,
  },
  500: {
    heading: <>Internal server error!</>,
    text: (
      <>
        The server has been deserted for a while.
        <br />
        Please be patient or try again later
      </>
    ),
    buttons: ['back', 'retry'],
    icon: IcoExclamationCircle,
  },
  503: {
    heading: <>We’ll be back soon!</>,
    text: (
      <>We&apos;re quickly updating our site for a better experience. Hang tight, we&apos;ll be back shortly. Thanks for your patience!</>
    ),
    buttons: ['retry'],
    icon: IcoRGear,
  },
  // default
  0: {
    heading: <>Oops! Something went wrong...</>,
    text: <>Please reload the page and try again</>,
    buttons: ['back', 'retry'],
    icon: IcoExclamationCircle,
  },
};

type Props = {
  type: ErrorTypes;
  className?: string;
};

export const ErrorPage = ({ type = 0, className }: Props) => {
  const { isSm } = useBreakpoints();
  const goBack = () => window.history.back();
  const reload = () => window.location.reload();

  const { heading, text, buttons, icon: Icon } = pageContent[type];

  return (
    <div className={cn(s.wrapper, className)}>
      <Container>
        <Flex flow={'column'} alignItems={'center'} justifyContent={'center'} gap={isSm ? 16 : 30} style={{ zIndex: 2 }}>
          <Flex flow={'column'} className={s.content}>
            <div className={s.icon}>
              <Icon width={80} height={100} />
            </div>
            <div className={s.heading}>{heading}</div>
            <div className={s.text}>{text}</div>
          </Flex>
          <Flex alignItems={'center'}>
            {buttons.includes('back') && (
              <Button modification={'primaryOutlined'} size="large" onClick={goBack}>
                Go Back
              </Button>
            )}
            {buttons.includes('retry') && (
              <Button modification={'primary'} size="large" onClick={reload}>
                {type === 503 ? 'Reload now' : 'Try again'}
              </Button>
            )}
          </Flex>
        </Flex>
      </Container>
      {type === 503 && <AnimatedBackground />}
    </div>
  );
};
