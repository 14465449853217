// TODO: Remove commented code and unused types
import { Patch } from '@/shared/types/api';
import { ICustomer, ISupport, IWriter } from '@/shared/types/participants';
import { Ticket } from '@/types/ticket';
import { ReactNode } from 'react';

// Common types for Message
export type MessengerViewContext = 'ticket';
// export type CommonMessageActions = 'remove' | 'retract';
export type MessengerChannels = 'customer' | 'writer' | 'public' | 'private';

export enum CommonMessageStatuses {
  Posted = 1,
  Removed,
  Retracted,
  Unchecked,
  Declined,
}

// Enums
export enum CommonMessageVisibilities {
  User = 1,
  Customer,
  Writer,
  Everyone,
}

// Local config
export interface MessengerConfig<Channels = MessengerChannels> {
  websockets: {
    ticket: string;
    issue?: string;
  };
  colors: {
    muted: string;
    support: string;
    writer: string;
    customer: string;
    primary: string;
  };
  icons: {
    disconnected: ReactNode;
    customer: ReactNode;
    writer: ReactNode;
    user: ReactNode;
    unknown: ReactNode; // TODO: Cover this case
    messages: ReactNode;
    quote: ReactNode;
    spinner: ReactNode;
    reply: ReactNode;
    actions: ReactNode;
    cancel: ReactNode;
    edit?: ReactNode;
    [CommonMessageStatuses.Posted]: ReactNode;
    [CommonMessageStatuses.Removed]: ReactNode;
    [CommonMessageStatuses.Retracted]: ReactNode;
    [CommonMessageStatuses.Unchecked]: ReactNode;
    [CommonMessageStatuses.Declined]: ReactNode;
  };
  channels: {
    [key in Channels as string]: {
      label: string;
      idVisibility: CommonMessageVisibilities; // default posting
      direction: string;
    };
  };
  defineStatusIconVisibility?: (hoverActionsAvailable: boolean, idStatus: CommonMessageStatuses) => boolean;
  renderMessage: {
    date: MsgElement;
  };
}

// export type MessengerParticipants = {
//   writer?: IWriter | null;
//   customer?: ICustomer | null;
// };

export interface CommonMessage {
  id: number;
  dateAdded: string;
  text: string;
  idStatus: CommonMessageStatuses; // Unique
  parentMessage?: CommonMessage; // useless?
  threadMessages?: CommonMessage[]; // recursively
  idParentMessage?: number;
  idThreadMessage?: number;
  idVisibility: CommonMessageVisibilities;

  title?: string; // TODO: useless
  author?: ISupport; // TODO: less data
  writer?: IWriter; // TODO: less data
  customer?: ICustomer; // TODO: less data

  // -- flags
  isRead?: boolean; // writer | customer systems
  isReadByCustomer?: boolean; // only support system
  isReadByWriter?: boolean; // only support system
  edited?: boolean;
}

export type WSCommonMessage = CommonMessage & {
  idIssue?: number; // possible
  idTicket?: number; // possible
  idParentMessage: number;
};

export interface ISupportMessage extends CommonMessage {
  author: ISupport;
}

export interface IWriterMessage extends CommonMessage {
  writer: IWriter;
}

export interface ICustomerMessage extends CommonMessage {
  customer: ICustomer;
}

export type CommonMessageCreatePayload = {
  text: string;
  idParentMessage?: number;
  idVisibility?: CommonMessageVisibilities;
  idTicket: number;
};
export type CommonMessageContextCreateRequest = CommonMessageCreatePayload;

export type CommonMessageUpdatePayload = { text: string };
export type CommonTicketMessageUpdateRequest = Patch<CommonMessageUpdatePayload> & { idTicket: number };

// TODO: Check all system usages of this type
// export type CommonTicketMessageCreateRequest = CommonMessageContextCreateRequest;
export type CommonTicketPatchMessage = {
  id: number;
  idTicket: number;
};

export type MsgElement = (msg: CommonMessage, hoverEnabled?: boolean) => ReactNode;

export type CommonTicketMessagesReadRequest = {
  ids: number[]; // required for API request
  idTicket: number; // required for cache update
};

export type CommonTicketMessagesReadResponse = number[]; // list of ids of updated messages

export type CommonTicketWithUnreadMessages = Ticket & {
  writer?: IWriter | null;
  customer?: ICustomer | null;
  unreadMessages: CommonMessage[];
};
export type CommonTicketWithUnreadMessagesResponse = CommonTicketWithUnreadMessages[];

// Messenger type guards
export const isSupportMessage = (msg: CommonMessage): msg is ISupportMessage => !!msg.author;
export const isWriterMessage = (msg: CommonMessage): msg is IWriterMessage => !!msg.writer;
export const isCustomerMessage = (msg: CommonMessage): msg is ICustomerMessage => !!msg.customer;

// Common type guards
// export const hasAuthor = <T extends { author?: ISupport }>(obj: T): obj is { author: ISupport } & T => !!obj.author;
// export const hasWriter = <T extends { writer?: IWriter }>(obj: T): obj is { writer: IWriter } & T => !!obj.writer;
// export const hasCustomer = <T extends { customer?: ICustomer }>(obj: T): obj is { customer: ICustomer } & T => !!obj.customer;
