import { USER_DATA_KEY } from '@/config/constants';
import {
  CommonMessage,
  CommonMessageStatuses,
  isCustomerMessage,
  isSupportMessage,
  isWriterMessage,
} from '@/shared/features/messenger/types/messages';
import { ICustomer, IWriter } from '@/shared/types/participants';
import { upperFirst } from 'lodash';

export const isSupportSystem = () => USER_DATA_KEY === 'author';

export const getMessageSender = (msg: CommonMessage): 'author' | 'writer' | 'customer' | 'unknown' =>
  isSupportMessage(msg) ? 'author' : isWriterMessage(msg) ? 'writer' : isCustomerMessage(msg) ? 'customer' : 'unknown';
export const getDisplayName = (message: CommonMessage): string => {
  let displayName: string;

  if (isSupportMessage(message)) {
    const { author } = message;
    // Support's messages
    displayName =
      author.publicName || author.name || (isSupportSystem() ? `${author.firstName || ''} ${author.lastName}`.trim() : 'Support');
  } else {
    // Writer's | Customer's messages
    const sender = message[getMessageSender(message)] as ICustomer | IWriter;
    const name = sender?.name || `${sender?.firstName || ''} ${sender?.lastName || ''}`;
    displayName = `#${sender?.id} ${name.trim() || upperFirst(getMessageSender(message))}`;
  }
  return displayName;
};

/**
 * Get unread count
 * at this moment feature available only only for support system
 * USER_DATA_KEY === 'author' <-> Support system
 * TODO: Refactor after extend CommonMessage (needful key read:boolean)
 * @param messages
 */
export const getUnreadCount = (messages: (CommonMessage | undefined)[]) =>
  messages.filter(
    (msg) =>
      USER_DATA_KEY === 'author'
        ? msg?.idStatus === CommonMessageStatuses.Unchecked // support system
        : // NOTE: Always compare with false, because isRead can be undefined or null
          msg?.isRead === false, // customer | writer
  ).length;
export const scrollToMessageById = (id: number) => {
  document.getElementById(`msg-id-${id}`)?.scrollIntoView({ behavior: 'smooth', block: 'end' });
};

// Fix messages sorting during updating message board (WS)
