import { ICountry } from '@/types/country';
import { Space, Tooltip } from 'antd';
import cn from 'classnames';
import { FC } from 'react';

type CountryPreviewProps = {
  iconOnly?: boolean;
  country: ICountry;
};

export const CountryPreview: FC<CountryPreviewProps> = ({ country, iconOnly }) => {
  return iconOnly ? (
    <Tooltip title={country.name}>
      <div className="react-tel-input">
        <div className={cn('flag', country.short.toLowerCase())} />
      </div>
    </Tooltip>
  ) : (
    <Space>
      <div className="react-tel-input">
        <div className={cn('flag', country.short.toLowerCase())} />
      </div>
      <div>{country.name}</div>
    </Space>
  );
};
