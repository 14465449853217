import { useUpdateProfilePictureMutation, useViewCurrentUserProfileQuery } from '@/api/profile';
import { UPLOAD_IMAGE_FILES } from '@/config/constants';
import { UploadOwnFile } from '@feature/file/upload/UploadOwnFile';
import cn from 'classnames';
import { FC, ReactNode, useCallback, useState } from 'react';

// Styles
import s from './ProfileAvatar.module.less';

interface IProps {
  fallback: ReactNode; // always renders the fallback component when (1) the image fails to load or (2) the profile picture is unavailable.
  size?: number; // default is 32px
  uploadOnClick?: boolean;
}

export const ProfileAvatar: FC<IProps> = ({ fallback = null, size = 32, uploadOnClick = false }) => {
  const { data: profile } = useViewCurrentUserProfileQuery();
  const [loaded, setLoaded] = useState(true);
  const [updatePicture] = useUpdateProfilePictureMutation();

  // image load failed -> render 'fallback' element!
  const handleLoadError = useCallback(() => setLoaded(false), []);

  return (
    <UploadOwnFile
      accept={UPLOAD_IMAGE_FILES}
      openOnClick={uploadOnClick}
      onComplete={(file) => updatePicture({ picture: file.dynamicS3Link })}
      children={
        <span className={cn({ [s.clickable]: uploadOnClick })}>
          {!profile?.picture || !loaded ? (
            // show fallback when avatar isn't available or not loaded
            fallback
          ) : (
            <img
              className={s.picture}
              src={profile?.picture}
              style={{ display: 'block', width: size, height: size, borderRadius: size }}
              onError={handleLoadError}
              alt=""
            />
          )}
        </span>
      }
    />
  );
};
