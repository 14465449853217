import { useAppContext } from '@/app/contexts/AppContext';
import { ErrorPage } from '@/components/common/error/ErrorPage';
import { Container } from '@/components/common/layout/Container';
import { Header } from '@/components/common/layout/Header';
import { Sidebar } from '@/components/common/layout/Sidebar';
import { APP_VERSION } from '@/config/constants';
import { useOrderformFlags } from '@/hooks/helpers';
import { useAppSelector } from '@/hooks/redux';
import { useAuth } from '@/store/auth/authHooks';
import { selectMaintenance, selectMenuCollapsed } from '@/store/global/globalSlice';
import { ReactNode } from 'react';
import s from './AppBaseLayout.module.less';

import cn from 'classnames';
import { PromoBadge } from '@/components/common/layout/promoBadge/PromoBadge';

type Props = {
  children: ReactNode;
};

export const AppBaseLayout = ({ children }: Props) => {
  const isMaintenance = useAppSelector(selectMaintenance);
  const { isLoggedIn } = useAuth();
  const {
    config: { site },
  } = useAppContext();
  const { isOrderFormPage } = useOrderformFlags();
  const menuCollapsed = useAppSelector(selectMenuCollapsed);
  const sidebarCollapsed = menuCollapsed || isOrderFormPage;

  return (
    <div
      className={cn(s.wrapper, {
        'app-sidebar-collapsed': sidebarCollapsed,
        'app-sidebar-full': !sidebarCollapsed,
      })}
    >
      <Header className={s.header} />

      <div className={s.main}>
        {isLoggedIn && <Sidebar className={s.sidebar} />}
        <div className={s.page}>
          <PromoBadge />
          <div className={s.content}>{isMaintenance ? <ErrorPage type={503} /> : children}</div>
          {!isOrderFormPage && (
            <footer className={s.footer}>
              <Container>
                <div className={s.footerContent}>
                  © {site.name} {new Date().getFullYear()}, v{APP_VERSION}. All rights reserved.
                </div>
              </Container>
            </footer>
          )}
        </div>
      </div>
    </div>
  );
};
