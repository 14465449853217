import { Button } from '@/components/common/button/Button';
import { Container } from '@/components/common/layout/Container';
import { Flex } from '@/components/common/layout/Flex';
import { HeaderMobileMenu } from '@/components/common/layout/HeaderMobileMenu';
import { AppLink } from '@/components/common/link/AppLink';
import { Logo } from '@/components/common/logo/Logo';
import { useBreakpoints } from '@/hooks/breakpoints/useBreakpoints';
import { useAuth } from '@/store/auth/authHooks';
import { ProfilePopover } from '@feature/profile/ProfilePopover';
import { UnreadTicketMessagesCounter } from '@feature/ticket/inbox/UnreadTicketMessagesCounter';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import s from './Header.module.less';

type Props = {
  className?: string;
};

export const Header = ({ className }: Props) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { isLoggedIn } = useAuth();

  const { isMd, isSm } = useBreakpoints();

  const lnkProps = { className: s.link, activeClassName: s.linkActive };

  const isNewOrderPage = pathname === '/order';
  const isSignInPage = pathname === '/login';

  return (
    <div className={cn(s.wrapper, className)}>
      <Container className={s.container}>
        <Logo />

        <Flex alignItems="center">
          {isLoggedIn && <UnreadTicketMessagesCounter />}

          {isMd && isLoggedIn ? (
            <HeaderMobileMenu />
          ) : (
            <Flex gap={24}>
              {!isNewOrderPage && (
                <AppLink to={'/order'} {...lnkProps}>
                  <Button modification={'primaryOutlined'} icon={'IcoRPlus'} circle={isSm}>
                    {isSm ? '' : t('header.orderBtn')}
                  </Button>
                </AppLink>
              )}

              {isLoggedIn ? (
                <ProfilePopover />
              ) : (
                !isSignInPage && (
                  <AppLink to={'/login'} {...lnkProps}>
                    <Button icon={'IcoRArrowRightToBracket'} circle={isSm}>
                      {isSm ? '' : t('header.signIn')}
                    </Button>
                  </AppLink>
                )
              )}
            </Flex>
          )}
        </Flex>
      </Container>
    </div>
  );
};
