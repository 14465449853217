/**
 * Created by floatrx on 25.09.2022
 */
import cn from 'classnames';
import { FC } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

// Styles
import s from './AppLink.module.scss';

interface IProps extends NavLinkProps {
  className?: string;
  activeClassName?: string;
  type?: 'primary' | 'secondary';
}

export const AppLink: FC<IProps> = ({ className, activeClassName, type = 'primary', ...props }) => (
  <NavLink
    end
    className={({ isActive }) =>
      cn(className ?? s.link, s[type], {
        [activeClassName ?? s.active]: isActive,
      })
    }
    {...props}
  />
);
