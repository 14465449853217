import { Pagination } from 'antd';
import type { LabelsResolver } from '../helpers';

type SearchEnginePaginationProps = {
  onChange?: ((current: number, size: number) => void) | undefined;
  size?: number;
  page?: number;
  total?: number;
  pageSizeOptions: number[];
  t: LabelsResolver;
};

export const SearchEnginePagination = ({ total, size, page, onChange, pageSizeOptions, t }: SearchEnginePaginationProps) => (
  <Pagination
    total={total || 0}
    showSizeChanger
    pageSize={size || 5}
    pageSizeOptions={pageSizeOptions}
    current={page}
    onShowSizeChange={onChange}
    onChange={onChange}
    showTotal={(total) => <>{t.resolve('totalItems', { count: total })}</>}
  />
);
