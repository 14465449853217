import { ws } from '@/helpers/ws';
import { useAppSelector } from '@/hooks/redux';
import { tokensSelector } from '@/store/auth/authSlice';
import { useEffect } from 'react';

export const useWS = () => {
  const { accessToken, refreshToken } = useAppSelector(tokensSelector);

  useEffect(() => {
    // case when access token is being refreshed or not logged in;
    if (!accessToken || accessToken === refreshToken) {
      // close all connections
      ws.removeToken();
      return;
    }

    ws.addToken(accessToken);
  }, [accessToken, refreshToken]);
};
