import { getCookie } from '@/helpers/cookie'; // https://github.com/codler/react-ga4#reactgaeventoptions
import { IOrderDetails } from '@/types/order';
import GA4 from 'react-ga4';
import { omit } from 'lodash';

// https://github.com/codler/react-ga4#reactgaeventoptions
type TGoogleTagEventOptions = {
  category: 'Site' | 'Dashboard' | 'Auth' | 'Order' | 'ExtraFeatures';
  action: string;
  label?: string;
  value?: number;
  nonInteraction?: boolean;
};

// Should be removed after a few months...
// last update: 6Jun, 2023
export const _g4log = (...args) => console.log('[🚀GA4]', ...args);

export const trackEvent = (options: TGoogleTagEventOptions, params: Record<string, string | number> = {}) => {
  _g4log('[⛳️trackEvent]', JSON.stringify(options));

  // eslint-disable-next-line camelcase
  GA4.event(options.action, { ...omit(options, 'action', 'category'), ...params, event_category: options.category });
};

export const trackPurchase = (order: IOrderDetails, conversionLabelId: string | undefined) => {
  const utmMedium = getCookie('utm_medium');
  const paymentHash = new URLSearchParams(window.location.search).get('hash') || btoa(`${order.id}${order.dynamicBalance}`);
  const coupon = order.snapshot?.discount?.code;
  const value = order.snapshot?.dynamicTotalPrice; // order total price

  const logInfo = { utmMedium, paymentHash, coupon, value };

  if (localStorage.getItem(paymentHash)) return _g4log('[trackPurchase]', { skip: true }, logInfo); // already sent

  // Sync with Google Ads
  // NOTE: Implementation for only one active campaign with same conversionLabelId (from site preferences)
  if (utmMedium === 'cpc' && conversionLabelId) {
    /* eslint-disable camelcase */
    GA4.event('conversion', {
      value,
      currency: 'USD',
      send_to: conversionLabelId,
      transaction_id: paymentHash, // TODO: Add real transactionId
    });
    /* eslint-enable camelcase */
    _g4log('[trackPurchase] Send to "Google Ads" as conversion', conversionLabelId, logInfo);
  }

  /* eslint-disable camelcase */
  // Fire purchase
  const eCommerceOrderItem = {
    transaction_id: paymentHash, // TODO: Add real transactionId
    value,
    coupon,
    items: [
      {
        item_id: order.id,
        item_name: order.snapshot?.title,
        coupon,
        item_brand: order.snapshot?.workType?.customerName,
        item_category: order.snapshot?.paperType?.customerName,
        item_category2: order.snapshot?.academicDiscipline?.customerName,
        item_category3: order.snapshot?.academicLevel?.customerName,
        item_category4: order.snapshot?.citationStyle?.customerName,
        item_variant: order.snapshot?.academicLevel?.customerName,
        price: value,
        quantity: 1,
      },
    ],
  };
  /* eslint-enable camelcase */

  GA4.event('purchase', eCommerceOrderItem);

  _g4log('[trackPurchase]', eCommerceOrderItem);

  localStorage.setItem(paymentHash, 'sent');
};
