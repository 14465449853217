import { IconSvg, IIconSVG } from '@/components/common/icons/IconSvg';
import { Flex } from '@/components/common/layout/Flex';
import { Button as AntButton } from 'antd';
import { NativeButtonProps } from 'antd/lib/button/button';

import cn from 'classnames';
import { FC } from 'react';

import s from './Button.module.less';

// Button -> synced with Button.module.less
export type ButtonModifications =
  // Types
  | 'default'
  | 'link'
  | 'text'
  | 'cta'
  | 'primary'
  | 'primaryOutlined'
  | 'success'
  | 'successOutlined'
  | 'warning'
  | 'warningOutlined'
  | 'danger'
  | 'dangerOutlined'
  | 'info'
  | 'infoOutlined'
  | 'ghost'
  | 'ghostInverted'
  | 'gradient'
  | 'unable';

export interface ButtonProps extends Omit<Partial<NativeButtonProps>, 'id' | 'type'> {
  icon?: IIconSVG;
  className?: string;
  modification?: ButtonModifications; // new! -> Button.module.less
  circle?: boolean;
}

type Props = ButtonProps;

export const Button: FC<ButtonProps> = ({ icon, children, loading, modification = 'default', className, circle, ...props }: Props) => {
  return (
    <AntButton
      shape={circle ? 'circle' : 'default'}
      loading={loading}
      className={cn(s.wrapper, className, {
        [s[`${props.size}`]]: props.size,
        [s[`${modification}`]]: !!modification,
      })}
      {...props} // supports all antd button props
    >
      <Flex gap={8} alignItems={'center'} justifyContent={'center'}>
        {loading && <IconSvg icon={'IcoRLoader'} className={s.loadingIcon} />}
        {icon && !loading && <IconSvg icon={icon} />}
        {/* Wrapped content */}
        {!circle && children}
      </Flex>
    </AntButton>
  );
};
