import { WSCloseCodes } from '@/shared/features/websockets/types/websockets';
// import { msg } from '@/shared/helpers/msg';
import { WS_HEALTH_CHECK_INTERVAL, WS_PING_PONG_INTERVAL } from '@/shared/features/websockets/config/config';
import { wsLog } from '@/shared/features/websockets/helpers/log';

export class AppWebsocketPingator {
  protected connection: WebSocket;
  protected onClose: () => void;
  protected pingIntervalId: ReturnType<typeof setInterval> | undefined;
  protected healthCheckIntervalId: ReturnType<typeof setInterval> | undefined;

  constructor(connection: WebSocket, onClose: () => void) {
    this.connection = connection;
    this.onClose = onClose;
  }

  public reset() {
    this.destroy();
    this.pingIntervalId = setInterval(() => this.launch(), WS_PING_PONG_INTERVAL);
  }

  public destroy() {
    clearInterval(this.pingIntervalId);
    clearInterval(this.healthCheckIntervalId);
  }

  public stopHealthCheck() {
    clearInterval(this.healthCheckIntervalId);
  }

  protected launch() {
    this.connection.send(JSON.stringify({ event: { id: 1 } })); // Ping payload

    // interval should cancel when onMessage -> received event.name === 'Pong'
    this.healthCheckIntervalId = setTimeout(() => {
      this.destroy();
      this.connection?.close(WSCloseCodes.GoneAway); // Going Away

      this.onClose();
      wsLog('🚜💨gone away');

      // return msg.error('Messenger service not available');
    }, WS_HEALTH_CHECK_INTERVAL);
  }
}
