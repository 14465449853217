import { useAppContext } from '@/app/contexts/AppContext';
import { trackEvent } from '@/helpers/gtag';
import { useAuth } from '@/store/auth/authHooks';
import { loadChatScript } from '@feature/chat/chat';
import { capitalize, lowerCase, upperFirst } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

const _log = (...args) => console.log('%cChatwoot', 'background:purple;color:white;', ...args);

export const ChatwootWidget = () => {
  const { config: preferences } = useAppContext();
  const chatToken = preferences?.site?.chatToken || '';

  const { user: customer } = useAuth();
  const { pathname } = useLocation();
  const [prevUserId, setPrevUserId] = useState(customer.id); // fix some issues with magic link

  const [chatInitialized, setChatInitialized] = useState(false);
  const checkCounterRef = useRef(0);

  const signInUser = useCallback(() => {
    _log('Sign in');
    const userName =
      String(`${customer.firstName || ''} ${customer.lastName || ''}`)
        .replace(/-/g, '')
        .trim() || 'customer';

    /* eslint-disable camelcase */
    const userInfo = {
      name: `#${customer.id} • ${capitalize(userName)}`,
      email: customer.primaryEmail?.address,
      phone_number: customer.primaryPhone ? '+' + customer.primaryPhone.idCallingCode + customer.primaryPhone.number : '',
      company_name: window.location.host.replace(/.*?\.(.*?)\..*/, '$1'),
    };

    window['$chatwoot'].setUser(`uid ${customer.id}`, userInfo);

    _log(`setUser [${customer.id}]`, userInfo, customer);
  }, [customer]);
  const resetChat = useCallback(() => {
    if (!chatInitialized) return;
    window['$chatwoot'].reset();
  }, [chatInitialized]);

  /**
   * Sync chat with customer session / setUser
   */
  useEffect(() => {
    if (!chatToken) return;
    if (!chatInitialized) return;

    if (customer.id !== prevUserId) {
      setPrevUserId(customer.id);
      if (prevUserId) {
        resetChat();
      }
    }

    if (customer.id && customer.id === prevUserId) {
      setTimeout(() => {
        signInUser();
      }, 1000);
    }
  }, [customer.id, prevUserId, chatInitialized, signInUser, resetChat, chatToken]);

  /**
   * Sync navigation / setCustomAttributes
   */
  const getTitleFromPath = (path: string) => {
    const pathValue = String(path).trim();
    if (!pathValue) return '';
    return upperFirst(lowerCase(pathValue));
  };

  const setCustomAttributes = useCallback(() => {
    if (!chatInitialized) return;
    const { origin } = window.location;

    const path = {
      title: `${getTitleFromPath(pathname)}`,
      url: `${origin}${pathname}`,
    };

    window['$chatwoot'].setCustomAttributes(path);
  }, [pathname, chatInitialized]);

  useEffect(setCustomAttributes, [pathname, setCustomAttributes]);

  /**
   * Check availability
   */
  useEffect(() => {
    if (!chatToken) return _log('Token is unavailable');

    let timer: ReturnType<typeof setTimeout>;

    const checkChatwoot = () => {
      checkCounterRef.current++;
      if (window['$chatwoot'] !== undefined && window['$chatwoot'].hasLoaded) {
        setChatInitialized(true);
        _log('Initialized successfully');

        // [global tracker] "ChatwootReady"
        trackEvent({ category: 'Dashboard', action: 'Chatwoot ready' });
      } else {
        if (checkCounterRef.current >= 10) return _log('Unavailable');
        timer = setTimeout(checkChatwoot, 500 * checkCounterRef.current);
      }
    };

    window.addEventListener('chatwoot:ready', checkChatwoot);

    return () => {
      clearTimeout(timer);
      window.removeEventListener('chatwoot:ready', checkChatwoot);
    };
  }, [chatToken]);

  // [global tracker] "ChatwootFails"
  useEffect(() => {
    window.addEventListener('chatwoot:error', function () {
      trackEvent({ category: 'Dashboard', action: 'Chatwoot failed' });
    });
  }, []);

  /**
   * Init chat on preferences fetched!
   */
  useEffect(() => {
    loadChatScript(preferences?.site?.chatToken || '');
  }, [preferences?.site?.chatToken]);

  return null;
};
