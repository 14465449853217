import { Date } from '@/components/common/Date';
import { CustomerIcon } from '@/components/common/icons/CustomerIcon';
import { IconSvg } from '@/components/common/icons/IconSvg';
import { UserIcon } from '@/components/common/icons/UserIcon';
import { WriterIcon } from '@/components/common/icons/WriterIcon';
import { TIME_FORMAT } from '@/config/constants';
import { getColor } from '@/helpers/utils';
import {
  CommonMessageStatuses,
  CommonMessageVisibilities,
  MessengerChannels,
  MessengerConfig,
} from '@/shared/features/messenger/types/messages';
import moment from 'moment';

export const MESSENGER_CONFIG: MessengerConfig<Extract<MessengerChannels, 'customer' | 'public'>> = {
  websockets: {
    ticket: 'customers/messages',
  },
  icons: {
    disconnected: '🔌 Offline',
    customer: <CustomerIcon size={22} />,
    writer: <WriterIcon size={22} />,
    user: <UserIcon size={22} />,
    unknown: <IconSvg size={22} icon={'IcoRUser'} />,
    messages: <IconSvg size={12} icon={'IcoRMessages'} />,
    quote: <IconSvg size={18} icon={'IcoRPenLine'} />,
    spinner: <IconSvg size={22} icon={'IcoRLoader'} />,
    reply: <IconSvg size={22} icon={'IcoRReply'} />,
    actions: <IconSvg size={22} icon={'IcoMore'} />,
    cancel: <IconSvg size={22} icon={'IcoRXmark'} />,
    [CommonMessageStatuses.Unchecked]: <IconSvg icon={'IcoRCheck'} />,
    [CommonMessageStatuses.Posted]: <IconSvg icon={'IcoRCheckDouble'} />,
    [CommonMessageStatuses.Declined]: <IconSvg icon={'IcoRCheckDouble'} />,

    [CommonMessageStatuses.Removed]: <IconSvg icon={'IcoRTrash'} />,
    [CommonMessageStatuses.Retracted]: <IconSvg icon={'IcoRXmark'} />,
  },

  colors: {
    primary: getColor('muted'),
    customer: getColor('customer'),
    writer: getColor('writer'),
    support: getColor('support'),
    muted: getColor('text'),
  },

  channels: {
    // Chat with support (customer private channel)
    customer: {
      label: '#support',
      idVisibility: CommonMessageVisibilities.Customer,
      direction: 'You & Support',
    },
    // Chat with writer (writer private channel)
    public: {
      label: '#writer',
      idVisibility: CommonMessageVisibilities.Everyone,
      direction: 'You & Writer',
    },
  },

  renderMessage: {
    date: ({ dateAdded }) => {
      const now = moment();
      const isSameDay = now.diff(dateAdded, 'day') <= 1;
      return <Date date={dateAdded} format={isSameDay ? TIME_FORMAT : `DD MMM ${TIME_FORMAT}`} />;
    },
  },
};
