// Prevents update state for unmounted components
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useIsMounted = () => {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return isMounted;
};

export const useQueryString = (param: string): unknown => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  return params.get(param);
};

export const useEditingMode = (flag) => {
  const [isEditing, setIsEditing] = useState(flag);
  const startEdit = () => setIsEditing(true);
  const finishEdit = () => setIsEditing(false);
  return { isEditing, startEdit, finishEdit };
};

export const useOrderformFlags = () => {
  const { pathname } = useLocation();
  const isOrderFormPage = pathname === '/order';
  return {
    isOrderFormPage,
  };
};
