import { DATE_FORMAT_DEFAULT } from '@/config/constants';
import moment from 'moment';
import { useMemo } from 'react';

type Props = {
  date: string | undefined,
  format?: string,
  className?: string
}

export const Date = ({ date, format = DATE_FORMAT_DEFAULT, className }:Props) => {
  const formattedDate = useMemo(()=> date && moment.utc(date).local().format(format), [date, format])
  if (!date) return null
  return <time className={className}>{formattedDate}</time>
}