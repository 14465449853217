import { useViewCurrentUserProfileQuery } from '@/api/profile';
import { ErrorPage } from '@/components/common/error/ErrorPage';
import { SpinFullscreen } from '@/components/common/spin/SpinFullscreen';
import { IS_DEV } from '@/config/constants';
import React, { lazy } from 'react';
import { Navigate, Route, Routes, useSearchParams } from 'react-router-dom';

const ThemeCustomizerPage = IS_DEV && lazy(() => import('@/components/theme/ThemeCustomizerPage'));

const ProfilePage = lazy(() => import('@/components/pages/ProfilePage'));
const OrderSinglePage = lazy(() => import('@/components/pages/OrderSinglePage'));
const OrderSearchPage = lazy(() => import('@/components/pages/OrderSearchPage'));
const RevisionSinglePage = lazy(() => import('@/components/pages/RevisionSinglePage'));
const RevisionSearchPagePage = lazy(() => import('@/components/pages/RevisionSearchPage'));
const TicketSearchPage = lazy(() => import('@/components/pages/TicketSearchPage'));
const TicketSinglePage = lazy(() => import('@/components/pages/TicketSinglePage'));
const CustomerExternalCredentialSearchPage = lazy(() => import('@/components/pages/CustomerExternalCredentialSearchPage'));
const IncomingTransactionSearchPage = lazy(() => import('@/components/pages/IncomingTransactionSearchPage'));
// const PreferredWriterSearchPage = lazy(() => import('@/components/pages/PreferredWriterSearchPage'));
// const PreferredWriterSinglePage = lazy(() => import('@/components/pages/PreferredWriterSinglePage'));
// LEGACY

const InboxTicketMessagesPage = lazy(() => import('@/components/features/ticket/inbox/InboxTicketMessagesPage'));

export const UserRoutes = () => {
  const [searchParams] = useSearchParams();
  const { data: profile } = useViewCurrentUserProfileQuery();

  if (!profile) {
    return <SpinFullscreen />;
  }

  return (
    <Routes>
      <Route
        index
        element={
          <Navigate
            to={{
              pathname: '/orders',
              search: searchParams.toString(),
            }}
            replace={true}
          />
        }
      />
      {/* <Route path={'/preferred-writers'} element={<PreferredWriterSearchPage />} />*/}
      {/* <Route path={'/preferred-writers/:id'} element={<PreferredWriterSinglePage />} />*/}
      <Route path={'/orders'} element={<OrderSearchPage />} />
      <Route path={'/orders/:id'} element={<OrderSinglePage />} />
      <Route path={'/profile'} element={<ProfilePage />} />
      <Route path={'/transactions'} element={<IncomingTransactionSearchPage />} />
      <Route path={'/revisions'} element={<RevisionSearchPagePage />} />
      <Route path={'/revisions/:id'} element={<RevisionSinglePage />} />
      <Route path={'/tickets'} element={<TicketSearchPage />} />
      <Route path={'/tickets/:id'} element={<TicketSinglePage />} />
      <Route path={'/login-details'} element={<CustomerExternalCredentialSearchPage />} />
      {['/login', '/register'].map((path) => (
        <Route path={path} key={path} element={<Navigate to={'/orders'} />} />
      ))}

      {/* LEGACY  routes */}
      {profile?.legacyAccount &&
        ['/orderinfo/*', '/dashboard/*', '/loyalty/*', '/transactions/*'].map((path) => (
          <Route key={path} path={path} element={<Navigate to={'/orders'} />} />
        ))}

      {IS_DEV && !!ThemeCustomizerPage && <Route path={'/adm/theme'} element={<ThemeCustomizerPage />} />}

      <Route path={'/inbox'} element={<InboxTicketMessagesPage />} />

      <Route path={'*'} element={<ErrorPage type={404} />} />
    </Routes>
  );
};
