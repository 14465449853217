import { RootState } from '@/store/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface GlobalState {
  maintenance: boolean;
  menuCollapsed: boolean;
}

export const globalInitialState: GlobalState = {
  maintenance: false,
  menuCollapsed: false,
};

export const globalSlice = createSlice({
  name: 'global',
  initialState: globalInitialState,
  reducers: {
    setMaintenance: (state: GlobalState, { payload }: PayloadAction<boolean>) => {
      state.maintenance = payload;
    },
    setCollapsedMenu: (state: GlobalState, { payload }: PayloadAction<boolean>) => {
      state.menuCollapsed = payload;
    },
  },
});

export default globalSlice.reducer;

export const { setMaintenance, setCollapsedMenu } = globalSlice.actions;

// Selectors
export const selectMaintenance = ({ global }: RootState) => global.maintenance;
export const selectMenuCollapsed = ({ global }: RootState) => global.menuCollapsed;
