import { ComponentType, ReactNode, useState } from 'react';
import { Button, Form, Popover, PopoverProps, Radio, Select } from 'antd';
import type { LabelsResolver } from '../helpers';
import { SearchEngineLayout } from '@/shared/components/searchEngine/src/types';

type SearchSortPopoverProps = {
  keys: string[];
  sortType?: 'asc' | 'desc';
  sortAscIcon: ReactNode;
  sortDescIcon: ReactNode;
  sortAscAltIcon: ReactNode;
  sortDescAltIcon: ReactNode;
  t: LabelsResolver;
  popover?: ComponentType<PopoverProps>;
  layout: SearchEngineLayout;
};

export const SearchSortPopover = ({
  keys,
  sortType,
  sortAscIcon,
  sortDescIcon,
  sortAscAltIcon,
  sortDescAltIcon,
  layout,
  t,
  popover: PopoverComponent = Popover,
}: SearchSortPopoverProps) => {
  const [visible, setVisible] = useState(false);

  return (
    <div className="core-search-engine-sort-popover">
      <PopoverComponent
        title={t.resolve('sortPreferences')}
        placement={layout === 'mobile' ? 'bottomRight' : 'bottom'}
        trigger="click"
        visible={visible}
        onVisibleChange={setVisible}
        content={
          <>
            <Form.Item name="sortBy" label={t.resolve('by')} className="core-search-engine-sort-popover__select-wrap">
              <Select showSearch notFoundContent={null} className="core-search-engine-sort-popover__select">
                {keys.map((key) => (
                  <Select.Option key={key} value={key}>
                    {t.resolve('sortParams', { name: key })}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="sortType" label={t.resolve('sortOrder')} className="core-search-engine-sort-popover__btns-wrap">
              <Radio.Group buttonStyle="solid" className="core-search-engine-sort-popover__btns">
                <Radio.Button value="asc" className="core-search-engine-sort-popover__btn">
                  {sortAscIcon} {t.resolve('asc')}
                </Radio.Button>
                <Radio.Button value="desc" className="core-search-engine-sort-popover__btn">
                  {sortDescIcon} {t.resolve('desc')}
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
          </>
        }
      >
        <Button className="ant-btn-square">{sortType === 'asc' ? sortAscAltIcon : sortDescAltIcon}</Button>
      </PopoverComponent>
    </div>
  );
};
