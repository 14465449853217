import { THEME_COLORS } from '@/config/constants';
import { hexToRgb } from '@/helpers/utils';
import { useEffect } from 'react';

export const useCssVariables = (theme) => {
  useEffect(() => {
    if (theme) {
      const themeVariables = Object.entries(theme);
      // Update CSS variables
      themeVariables.forEach(([name, value]) => (name && value) && document.documentElement.style.setProperty(name, value as string));
    }
    // Create RGB colors from CSS VARIABLE COLORS
    THEME_COLORS.forEach(cName => {
      const rgb = getComputedStyle(document.documentElement).getPropertyValue(`--${cName}-color`);
      // todo: move to sheet[0] !!!
      document.documentElement.style.setProperty(`--${cName}-color-rgba`, hexToRgb(rgb));
    });

  }, [theme]);
}