import { createContext, useContext } from 'react';
import { TAppContext } from '@/types/common/components';
import { INITIAL_PREFERENCES } from '@/config/constants';

export const AppContext = createContext<TAppContext>({
  breakpoint: 'xxl',
  isTouchDevice: false,
  config: INITIAL_PREFERENCES,
});
export const useAppContext = () => useContext(AppContext);
