import { useUpdateProfilePreferencesMutation, useViewCurrentUserProfileQuery } from '@/api/profile';
import { useAuth } from '@/store/auth/authHooks';

export const useManageExtraFeatureOffers = () => {
  const { isLoggedIn } = useAuth();

  const { data: profile, refetch } = useViewCurrentUserProfileQuery(undefined, { skip: !isLoggedIn });
  const [updatePreferences] = useUpdateProfilePreferencesMutation();
  const toggleOffers = async (showOffers: boolean) => {
    updatePreferences({
      preferences: {
        ...(profile?.preferences || {}),
        hideExtraFeatureOffer: !showOffers,
      },
    }).then(refetch);
  };
  const disableOffers = () => toggleOffers(false);
  const enableOffers = () => toggleOffers(true);

  return {
    offersEnabled: !profile?.preferences?.hideExtraFeatureOffer,
    enableOffers,
    disableOffers,
    toggleOffers,
  };
};
